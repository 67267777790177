import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { formatTime } from "../../../utils/videoUtils";
import CommentInputBoxContainer from "./CommentInputBoxContainer";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from "@mui/icons-material/Delete";

const CommentDialog = ({
  open,
  handleClose,
  currentTime,
  comments,
  addComment,
  seekToTimestamp,
  handleCommentUpdate,
  handleDeleteComment,
}) => {
  const [inputText, setInputText] = useState("");

  const [editText, setEditText] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [commentClickedIndex, setCommentClickedIndex] = useState(-1);

  const handlePostBtnClick = () => {
    const newComment = {
      time: currentTime,
      text: inputText,
    };

    const currentSecond = Math.floor(currentTime);

    const existingComment = comments.find(
      (c) => Math.floor(c.time) === currentSecond
    );

    addComment(newComment);
    setInputText("");
  };

  const handleCommentClick = (timestamp) => {
    seekToTimestamp(timestamp);
    handleClose();
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setEditText(comments[index].text);
  };

  const handleDeleteClick = (index) => {
    handleDeleteComment(index);
    setCommentClickedIndex(-1);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          width: "30vw",
          background: "#282828",
          color: "#ffffff",
          padding: "",
          position: "absolute",
          top: 0,
          right: 0,
        },
      }}
    >
      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Comments</Typography>
        <IconButton sx={{ color: "white" }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ background: "white" }} />
      <DialogContent
        sx={{
          height: "60vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {comments.map((comment, index) => (
          <Box
            key={index}
            display={"flex"}
            alignItems={"start"}
            gap={2}
            sx={{ width: "100%" }}
          >
            <Typography
              fontSize={"12px"}
              onClick={() => {
                handleCommentClick(comment.time);
              }}
            >
              {formatTime(comment.time)}
            </Typography>

            <Box
              position={"relative"}
              onClick={(e) => setCommentClickedIndex(index)}
              sx={{
                fontSize: "12px",
                backgroundColor: "#3c3c3c",
                padding: "5px 10px",
                borderRadius: 2,
                width: "100%",
                cursor: "pointer",
              }}
            >
              {commentClickedIndex === index && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setCommentClickedIndex(-1);
                    setEditIndex(-1);
                  }}
                  size="small"
                  sx={{
                    color: "white",
                    position: "absolute",
                    right: 0,
                    top: -1,
                    zIndex: 10,
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
              {editIndex === index ? (
                <Box
                  width={"100%"}
                  bgcolor={"#3c3c3c"}
                  borderRadius={2}
                  sx={{ paddingBottom: "1rem" }}
                >
                  <InputBase
                    multiline
                    fullWidth
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    sx={{
                      fontSize: "12px",
                      backgroundColor: "#3c3c3c",
                      padding: 1,
                      borderRadius: 2,
                      color: "white",
                    }}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    disableTouchRipple
                    sx={{
                      backgroundColor: "black",
                      fontSize: "12px",
                      position: "absolute",
                      bottom: 8,
                      left: "40%",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCommentUpdate(editIndex, editText);
                      setEditIndex(-1);
                      setCommentClickedIndex(-1);
                    }}
                  >
                    POST
                  </Button>
                </Box>
              ) : (
                <Typography variant="body2">{comment.text}</Typography>
              )}
              <Stack
                direction={"row"}
                pt={0.5}
                justifyContent={editIndex === index ? "end" : "space-between"}
                alignItems={"center"}
              >
                {editIndex === index ? (
                  ""
                ) : (
                  <span style={{ fontSize: "10px", color: "GrayText" }}>
                    Tue,01,APR
                  </span>
                )}
                <Stack
                  direction={"row"}
                  display={commentClickedIndex === index ? "flex" : "none"}
                >
                  <IconButton
                    size="small"
                    sx={{ color: "green" }}
                    onClick={() => handleEditClick(index)}
                  >
                    <EditRoundedIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="small"
                    sx={{ color: "red" }}
                    onClick={() => handleDeleteClick(index)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          </Box>
        ))}
      </DialogContent>
      <Box padding={2}>
        <CommentInputBoxContainer
          addComment={addComment}
          currentTime={currentTime}
        />
      </Box>
    </Dialog>
  );
};

export default CommentDialog;
