import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { formatTime } from "../../../utils/videoUtils";

const CommentInputBoxContainer = ({ addComment, currentTime }) => {
  const [inputText, setInputText] = useState("");
  const handlePostBtnClick = () => {
    const newComment = {
      time: currentTime,
      text: inputText,
    };
    addComment(newComment);
    setInputText("");
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={3}
      bgcolor={"#3c3c3c"}
      maxWidth={"100%"}
      px={1}
      py={1}
      borderRadius={2}
    >
      <Typography
        sx={{
          backgroundColor: "white",
          padding: "2px 8px",
          borderRadius: 1,
          color: "black",
        }}
      >
        {formatTime(currentTime)}
      </Typography>
      <input
        value={inputText}
        style={{
          border: "none",
          outline: "none",
          padding: "5px 2px",
          width: "100%",
          backgroundColor: "transparent",
          color: "white",
        }}
        placeholder="Enter Your Comment Here"
        onChange={(e) => setInputText(e.target.value)}
      />
      <Button variant="contained" size="small" onClick={handlePostBtnClick}>
        Post
      </Button>
    </Stack>
  );
};

export default CommentInputBoxContainer;
