import { useState, useEffect, useContext, useMemo } from "react";
import styles from "./freelancer-info.module.scss";
import Switch from "@material-ui/core/Switch";
import avatar from "assets/images/default.svg";
import callIco from "assets/images/call.svg";
import locationIco from "assets/images/location.svg";
import dateIco from "assets/images/date-ico.svg";
import mailIco from "assets/images/email-ico.svg";
import downloadIco from "assets/images/download-icon.svg";
import BackIcon from "assets/images/back-icon.svg";
import NoVideo from "assets/images/video-placeholder.svg";
import yetToSync from "assets/images/video-yet-sync.svg";
import synced from "assets/images/video-synced.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import videoIcon from "assets/images/video-icon.svg";
import addIcon from "assets/images/add-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";

import { Button, Menu, MenuItem, InputField, ConfirmDialog } from "components";
import EditFreelancer from "./edit-freelancer/edit-freelancer";
import selectall from "assets/images/select-all.svg";
import deleteIcon from "assets/images/delete.svg";
import { ReactComponent as DeleteIcon } from "assets/images/delete-outline.svg";
import { Link, useHistory } from "react-router-dom";
import VideoCard from "./video-card/video-card";
import { format } from "date-fns";
import moment from "moment";
import { Paper, Typography } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { checkBoolean, getBase64Image } from "utils/videoUtils";

const FreeLancerInfo = () => {
  const {
    dispatch,
    state: { isAdmin, userId },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const [open, setOpen] = useState(false);
  const [freelancerDetail, setFreelancerDetail] = useState();
  const [videoStatus, setVideoStatus] = useState();
  const history = useHistory();

  const [opensort, setOpenSort] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isBulkSelect, setIsBulkSelect] = useState(false);
  const [statusList, setStatus] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDownloadDialog, setOpenDownlaodDialog] = useState(false);

  const [videoCopy, setVideoCopy] = useState([]);
  const [yesterdayVideoCopy, setYesterdayVideoCopy] = useState([]);

  const [sortNameToggle, setSortNameToggle] = useState(false);
  const [sortTimeToggle, setSortTimeToggle] = useState(false);

  const [isSortActive, setIsSortActive] = useState(null);

  const [openPrompt, setOpenPrompt] = useState(false);

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  const [openAccountDeletePrompt, setOpenAccountDeletePrompt] = useState(false);

  const [unselectAll, setUnSelectAll] = useState(false);

  const [totalDeletedVideos, setTotalDeletedVideos] = useState(0);

  const [profileImg, setProfileImg] = useState();

  const [videoLists, setVideoLists] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchTerm = (value) => {
    setSearchTerm(value);
  };

  const timelineFormatDate = (date) => {
    if (moment(date, moment.ISO_8601, true).isValid()) {
      return moment(date).format("ddd, DD MMM YYYY");
    }
    return "-";
  };

  const handleSortClick = (event) => {
    setOpenSort(event.currentTarget);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason, showPrompt) => {
    setOpenSort(null);
    setOpen(false);
    showPrompt && setOpenPrompt(true);
  };

  useEffect(() => {
    !open && getFreelancerDetails();
  }, [open]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const getFreelancerDetails = async () => {
    try {
      storeHandler(actions.SHOW_LOADER, true);
      const { profile_details, video_stats, video, status } = userId.admin
        ? await AuthServices.getAdminDetail(userId.id)
        : await AuthServices.getFreelancerDetails(userId.id);
      const profInfo = { ...profile_details, video };
      profInfo.video = profInfo?.video?.map((item, i) => ({
        ...item,
        date: `${new Date(item.date).toDateString()}  ${new Date(
          item.date
        ).getHours()}:${new Date(item.date).getMinutes()}`,
      }));
      setFreelancerDetail(profInfo);
      setVideoStatus(video_stats);
      setIsActive(!!profile_details?.status);
      setVideoLists(video);
      storeHandler(actions.SHOW_LOADER, false);
      // if (profInfo?.video?.length) {
      //   const todayVideo = profInfo.video
      //     .filter((item) => item.today)
      //     .sort((a, b) => new Date(b.date) - new Date(a.date));
      //   const yesterdayVideo = profInfo.video
      //     .filter((item) => !item.today)
      //     .sort((a, b) => new Date(b.date) - new Date(a.date));
      //   catagorizeVideo(yesterdayVideo);
      //   setVideoCopy([...todayVideo]);
      //   setYesterdayVideoCopy([...yesterdayVideo]);
      // }
      // if (status) {
      //   const statusArray = Object.keys(status);
      //   const statusArraySort = statusArray
      //     .sort((a, b) => moment(a) - moment(b))
      //     .reverse(); // Date Sort by date order and reverse the date
      //   const statusValue = statusArraySort.map((item) => ({
      //     date: moment
      //       .utc(new Date(item), "ddd, DD MMM YYYY")
      //       .local()
      //       .format("ddd, DD MMM YYYY"),
      //     value: status[item],
      //   })); //Object changed utc from local time
      //   const filterSameDate = statusValue.map((oldDate) => oldDate.date); // Date mapped for filter function
      //   const filteredDate = statusValue.filter(
      //     ({ date }, index) => !filterSameDate.includes(date, index + 1)
      //   ); //Finally Removed duplicate data by date
      //   setStatus([...filteredDate]);
      // }
      if (!!profInfo?.profile) {
        setProfileImg(profInfo.profile);
        // getBase64Image(profInfo.profile, setProfileImg);
      }
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const getUserTimeLine = async (id) => {
    // {
    //   "video_id": "66dc969fc6f4ea0002664db9",
    //   "approval": true,
    //   "approval_on": "2024-09-07 18:27:51.021265+00:00",
    //   "approval_by": "gtest"
    // }

    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getUserTimeLine(id);
      setStatus(data);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  useEffect(() => {
    getUserTimeLine(userId?.id);
  }, []);

  const catagorizeVideo = (yesterdayVideo) => {
    const videoList = {};
    yesterdayVideo.forEach((item) => {
      const { date } = item;

      let key = date ? new Date(date).toLocaleDateString() : null;
      if (key) {
        if (videoList[key]) {
          videoList[key].push(item);
        } else {
          videoList[key] = [item];
        }
      }
    });

    // setVideoLists({ ...videoList });
  };
  const toggleActive = async (checked) => {
    const { admin, id } = userId;

    try {
      storeHandler(actions.SHOW_LOADER, true);

      if (admin) {
        checked
          ? await AuthServices.activateAdmin(id)
          : await AuthServices.deactivateAdmin(id);
      } else {
        checked
          ? await AuthServices.activateFreelancer(id)
          : await AuthServices.deactivateFreelancer(id);
      }
      setIsActive(checked);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const selectallVideos = () => {
    if (selectAll) {
      setSelectAll(!selectAll);
      setSelectedVideos([]);
    } else {
      setSelectAll(!selectAll);
      const urls = freelancerDetail?.video?.map((item) => item.video) || [];
      setSelectedVideos(urls);
    }
  };
  const onConfirm = () => {
    deleteVideos();
    setOpenDialog(false);
  };
  const deleteVideos = async (url) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { id } = userId;
      await AuthServices.deleteVideo(
        { urls: url ? [url] : [...selectedVideos] },
        id
      );
      storeHandler(actions.SHOW_LOADER, false);
      setOpenDeletePrompt(true);
      getFreelancerDetails();
      setUnSelectAll(true);
      setSelectAll(false);
      setTotalDeletedVideos(url ? 1 : selectedVideos?.length);
      setSelectedVideos([]);
      !url && udpateBulkSelect();
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const searchData = (value) => {
    const filteredData =
      freelancerDetail?.video?.filter(
        (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      ) || [];
    const todayVideo = filteredData?.filter((item) => item.today) || [];
    const yesterdayVideo = filteredData?.filter((item) => !item.today) || [];
    setVideoCopy([...todayVideo]);
    setYesterdayVideoCopy([...yesterdayVideo]);
    catagorizeVideo(yesterdayVideo);
  };

  const onConfirmDialog = () => {
    setOpenConfirmDialog(false);
    toggleActive(!isActive);
  };

  const onDownloadConfirm = () => {
    setOpenDownlaodDialog(false);
    const elements = document.getElementsByClassName("selected-videos");
    const files = elements.length ? [...elements] : [];
    files.forEach((item) => {
      item.click();
    });
    udpateBulkSelect();
  };

  const onAccountDeleteConfirm = async () => {
    const { admin, id } = userId;
    try {
      storeHandler(actions.SHOW_LOADER, true);

      if (admin) {
        await AuthServices.activateAdmin(id);
      } else {
        await AuthServices.deleteFreelancer(id);
      }
      storeHandler(actions.SHOW_LOADER, false);
      setOpenAccountDeletePrompt(false);
      history.push("/contractors");
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const openPopup = () => {
    setOpenConfirmDialog(true);
  };

  const onChangeNameSort = () => {
    setIsSortActive("name");

    const newToggle = !sortNameToggle;
    setSortNameToggle(newToggle);
    let sortedData = videoCopy.sort((a, b) =>
      newToggle ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );
    setVideoCopy(sortedData);
    let yestsortedData = yesterdayVideoCopy.sort((a, b) =>
      newToggle ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );
    setYesterdayVideoCopy(yestsortedData);
    catagorizeVideo(yestsortedData);
  };
  const onChangeTimeSort = () => {
    setIsSortActive("time");

    const newToggle = !sortTimeToggle;
    setSortTimeToggle(newToggle);
    let sortedData = videoCopy.sort((a, b) =>
      newToggle
        ? new Date(b.date) - new Date(a.date)
        : new Date(a.date) - new Date(b.date)
    );
    setVideoCopy(sortedData);
    let yestsortedData = yesterdayVideoCopy.sort((a, b) =>
      newToggle
        ? new Date(b.date) - new Date(a.date)
        : new Date(a.date) - new Date(b.date)
    );
    setYesterdayVideoCopy(yestsortedData);
    catagorizeVideo(yestsortedData);
  };

  const udpateBulkSelect = () => {
    setIsBulkSelect(!isBulkSelect);
    if (isBulkSelect) {
      // if bulk select is switched off. since setstate will take time to update the value assuming isBulkselect as switching off
      clearSelection();
      setSelectAll(false);
    }
  };
  const clearSelection = () => {
    setSelectedVideos([]);
    setUnSelectAll(true);
  };

  const filteredVideos = useMemo(() => {
    try {
      if (!searchTerm.trim()) {
        return videoLists;
      }

      return videoLists.filter((video) =>
        video.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } catch (err) {
      console.log("An error occurred while filtering the data.");
      return [];
    }
  }, [searchTerm, videoLists]);

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceInfoHeader} d-flex justify-content-between align-items-center flex-md-row flex-column`}
      >
        <Stack
          direction={"row"}
          gap={".5rem"}
          alignItems="center"
          className={!IS_ADMIN && styles.header}
        >
          <Typography
            variant="h4"
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              marginRight: "2rem",
            }}
          >
            {freelancerDetail?.name || ""}
          </Typography>

          {IS_ADMIN && (
            <ButtonWithIcon
              disableRipple
              endIcon={
                <FiberManualRecordIcon
                  style={{ color: isActive ? "green" : "var(--error-main)" }}
                />
              }
            >
              Active
            </ButtonWithIcon>
          )}
          {IS_ADMIN && (
            <Switch
              name="checkedA"
              color="primary"
              size="small"
              checked={isActive}
              onClick={openPopup}
            />
          )}
          {IS_ADMIN && (
            <div className="pl-3">
              <ButtonWithIcon
                disableRipple
                startIcon={<EditIcon />}
                onClick={handleClickOpen}
              >
                Edit Contractor
              </ButtonWithIcon>
            </div>
          )}
          <div className="pl-5">
            <InputField
              variant="outlined"
              placeholder="Search by video name "
              withAdronment={true}
              onChange={(event) => handleSearchTerm(event.target.value)}
            />
          </div>
        </Stack>

        {IS_ADMIN && (
          <ButtonWithIcon
            disableRipple
            startIcon={<DeleteIcon />}
            onClick={() => setOpenAccountDeletePrompt(true)}
          >
            Delete
          </ButtonWithIcon>
        )}
      </Paper>

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        className="p-2 py-3 "
      >
        <Stack
          direction={"row"}
          gap={".2rem"}
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/contractors")}
        >
          <KeyboardBackspaceIcon />
          <Typography variant="subtitle1">Back</Typography>
        </Stack>

        <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
          Account ID:{" "}
          <span style={{ color: "var(--error-main)" }}>
            {freelancerDetail?.account_id || ""}
          </span>
        </Typography>
      </Stack>

      <div className="d-flex flex-wrap">
        <div className={`flex-grow-1 ${styles.infoWrap}`}>
          <div className="d-flex flex-wrap">
            {/* <div className={`${styles.userInfo} d-flex justify-content-between flex-grow-1 py-3 col-12 flex-column flex-md-row`}>
                        <div className='d-flex flex-wrap justify-content-md-between align-items-center'>
                            <Link to={userId.admin ? '/admin' : "/freelancer"} className='mt-2 mr-3'><img src={BackIcon} alt="back" /></Link>
                            <span className='txt-xlg txt-bold mr-3'>{freelancerDetail?.name}</span>
                            {isActive ? <span className={`txt-md mt-2 mr-2 ${styles.badges} ${styles.active}`}>Active</span> :
                                <span className={`txt-md mt-2 mr-2 ${styles.badges} ${styles.inactive}`}>Inactive</span>}
                            <Switch
                                name="checkedA"
                                color="primary"
                                className='mt-2 mr-3'
                                checked={isActive}
                                onClick={openPopup}
                            />
                            <span className='txt-md mt-2 txt-primary cursor' onClick={handleClickOpen}> <img src={edit} alt="edit" /> Edit Profile</span>
                        </div>
                        <div className='d-flex justify-content-md-between align-items-center'>
                            <div><span className='txt-md mt-2 txt-grey mr-2 txt-light'>Account id:</span><span className={`txt-md mt-2 txt-primary txt-bold pr-3`}>{freelancerDetail?.account_id || freelancerDetail?.id}</span></div>
                        </div>
                    </div> */}
            <div
              className={`${styles.userDetails} d-flex justify-content-between flex-grow-1 flex-wrap flex-lg-row flex-column`}
            >
              <div
                className="d-flex justify-content-center"
                style={{
                  background: "black",
                  borderRadius: ".3rem",
                  padding: ".8rem",
                  minWidth: "18rem",
                  maxWidth: "18rem",
                }}
              >
                <div className={`${styles.avatar}`}>
                  <img src={profileImg || avatar} alt={"avatar"} />
                </div>
                <div className="d-flex flex-column justify-content-between pl-2">
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={callIco} alt="call" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.phone || "-"}
                    </span>
                    {/* <span className={`${styles.info}`}>
                      {freelancerDetail?.alternate_phone}
                    </span> */}
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={mailIco} alt="call" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.email || "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={dateIco} alt="location" className="mr-2" />

                    <span className={`${styles.info}`}>
                      {freelancerDetail?.date_joining
                        ? new Date(
                            freelancerDetail.date_joining
                          ).toLocaleDateString()
                        : "-"}
                    </span>
                  </p>
                  <p className="txt-light txt-smd txt-white mb-0 mt-1 d-flex align-items-center">
                    <img src={locationIco} alt="location" className="mr-2" />
                    <span className={`${styles.info}`}>
                      {freelancerDetail?.address || "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex-grow-1 d-flex justify-content-lg-end  justify-content-center align-item-end  flex-wrap">
                <div
                  className={`${styles.videoInfoCard} p-3 mr-3 my-2 my-lg-0`}
                >
                  <Typography>Videos Captured</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    p={".3rem"}
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {videoStatus?.captured}
                    </Typography>
                    <img
                      src={videoIcon}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>

                <div
                  className={`${styles.videoInfoCard} p-3 mr-3 my-2 my-lg-0`}
                >
                  <Typography>Videos Accepted</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    p={".3rem"}
                    alignItems="center"
                  >
                    <Typography variant="h2">
                      {videoStatus?.Accepted}
                    </Typography>
                    <img
                      src={synced}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>

                <div className={`${styles.videoInfoCard} p-3 my-2 my-lg-0`}>
                  <Typography>Videos Rejected</Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    p={".3rem"}
                  >
                    <Typography variant="h2">
                      {videoStatus?.Rejected}
                    </Typography>
                    <img
                      src={yetToSync}
                      alt="video"
                      className={`${styles.videoIcon}`}
                    />
                  </Stack>
                </div>
              </div>
            </div>
            {!userId.admin && (
              <>
                <div
                  style={{
                    width: "100%",
                    padding: ".5rem 0",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    my="1rem"
                  >
                    <Typography variant="h5">Videos</Typography>

                    {false && !!filteredVideos?.length && (
                      <Stack direction="row" gap="1.5rem">
                        <ButtonWithIcon disableRipple startIcon={<SortIcon />}>
                          Sort
                        </ButtonWithIcon>

                        <ButtonWithIcon
                          disableRipple
                          startIcon={<FilterIcon />}
                        >
                          Filter
                        </ButtonWithIcon>
                      </Stack>
                    )}
                  </Stack>

                  {/* {!!freelancerDetail?.video?.length && (
                    <div className="text-center d-flex flex-wrap align-items-center">
                      {isBulkSelect && (
                        <>
                          <Button
                            variant="outlined"
                            color="primary"
                            size={"small"}
                            className="py-sm-2 py-1 mr-3"
                            onClick={() => setOpenDownlaodDialog(true)}
                          >
                            <img
                              src={downloadIco}
                              alt="download-icon"
                              className={`${styles.btnImg}`}
                            />
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            size={"small"}
                            className="py-sm-2 py-1 mr-3"
                            onClick={() => setOpenDialog(true)}
                          >
                            <img
                              src={deleteIcon}
                              alt="dashboard-icon"
                              className={`${styles.btnImg}`}
                            />
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            size={"small"}
                            className="py-sm-2 py-1 mr-3"
                            onClick={selectallVideos}
                          >
                            <img
                              src={selectall}
                              alt="dashboard-icon"
                              className={`${styles.btnImg}`}
                            />
                          </Button>
                        </>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        size={"small"}
                        className="py-2 mr-4 my-md-0 my-2 w-md-auto"
                        onClick={udpateBulkSelect}
                      >
                        <img src={addIcon} alt="add-icon" className="mr-2" />{" "}
                        Bulk Select
                      </Button>
                      <InputField
                        variant="outlined"
                        className={"mr-3 filled-input my-md-0 my-2 w-md-auto"}
                        placeholder="Search by file name"
                        withAdronment={true}
                        onChange={(event) => searchData(event.target.value)}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        size={"small"}
                        className="py-2 px-3 my-md-0 my-2"
                        onClick={handleSortClick}
                      >
                        <img src={sort} alt="sort-icon" className="mr-1" /> Sort
                      </Button>
                      <Menu
                        anchorEl={opensort}
                        keepMounted
                        open={Boolean(opensort)}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom" }}
                      >
                        <MenuItem onClick={onChangeNameSort}>
                          <span
                            className={
                              isSortActive === "name" ? "txt-bold" : ""
                            }
                          >
                            Name
                          </span>
                        </MenuItem>
                        <MenuItem onClick={onChangeTimeSort}>
                          <span
                            className={
                              isSortActive === "time" ? "txt-bold" : ""
                            }
                          >
                            Time
                          </span>
                        </MenuItem>
                      </Menu>
                    </div>
                  )} */}
                </div>
                {!userId.admin && !filteredVideos?.length && (
                  <div className="text-center flex-grow-1">
                    <img
                      src={NoVideo}
                      alt="no video"
                      className={`${styles.noVideo}`}
                    />
                    <p className="txt-lg txt-primary my-0">
                      Oops There is no Recorded Video
                    </p>
                  </div>
                )}
                <div className="position-relative">
                  <div
                    className={`d-flex justify-content-end ${styles.videosCount}`}
                  >
                    <p>
                      {!!selectedVideos?.length &&
                        `${
                          selectedVideos?.length === 1
                            ? "1 Video"
                            : `${selectedVideos?.length} Videos`
                        } Selected`}
                    </p>
                  </div>
                  {/* {!!videoCopy.length && (
                    <div
                      className={`${styles.videoSection} py-4 d-flex justify-content-between flex-grow-1 flex-wrap col-12`}
                    >
                      <p className={`txt-smd txt-grey col-12`}>Today</p>
                      <div
                        className={`${styles.videoCardSection} d-flex flex-wrap col-12 justify-content-lg-start justify-content-center px-0`}
                      >
                        {videoCopy?.map((item, i) => (
                          <div className="pr-2 mb-3">
                            <VideoCard
                              deleteVideos={deleteVideos}
                              freelancerDetail={freelancerDetail}
                              unselectAll={unselectAll}
                              setUnSelectAll={setUnSelectAll}
                              info={item}
                              selectedVideos={selectedVideos}
                              setSelectedVideos={setSelectedVideos}
                              selectAll={selectAll}
                              index={i}
                              isBulkSelect={isBulkSelect}
                              getFreelancerDetails={getFreelancerDetails}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )} */}
                  {/* {!!yesterdayVideoCopy.length && <div className={`${styles.videoSection} py-4 d-flex justify-content-between flex-grow-1 flex-wrap col-12`}>
                                <p className={`txt-smd txt-grey col-12`}>
                                    Yesterday
                                </p>
                                <div className={`${styles.videoCardSection} d-flex flex-wrap col-12 justify-content-lg-start justify-content-center`}>
                                    {yesterdayVideoCopy?.map((item, i) => (
                                        <div className='pr-2 mb-3'>
                                            <VideoCard deleteVideos={deleteVideos} freelancerDetail={freelancerDetail} unselectAll={unselectAll} setUnSelectAll={setUnSelectAll} info={item} selectedVideos={selectedVideos} setSelectedVideos={setSelectedVideos} selectAll={selectAll} index={i} isBulkSelect={isBulkSelect} getFreelancerDetails={getFreelancerDetails} />
                                        </div>
                                    ))}
                                </div>
                            </div>} */}

                  <div
                    style={{
                      gap: "3rem",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {filteredVideos?.map((item, i) => (
                      <div>
                        <VideoCard
                          deleteVideos={deleteVideos}
                          freelancerDetail={freelancerDetail}
                          unselectAll={unselectAll}
                          setUnSelectAll={setUnSelectAll}
                          info={item}
                          selectedVideos={selectedVideos}
                          setSelectedVideos={setSelectedVideos}
                          selectAll={selectAll}
                          index={i}
                          isBulkSelect={isBulkSelect}
                          getFreelancerDetails={getFreelancerDetails}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {!userId.admin && (
          <div className={`pl-4 ${styles.timelineWrap} d-none d-lg-block`}>
            <div className={`${styles.timeline} p-3 h-100`}>
              <p className="txt-lg txt-primary mb-3 mt-0">Timelines</p>

              {!!statusList?.length &&
                statusList.map((item) => (
                  <div className={`${styles.status} mb-2 pb-2 txt-smd`}>
                    <div className="d-flex align-items-center">
                      <span
                        className={`${styles.statusIndicator} mr-2`}
                        style={{
                          background: item?.approval
                            ? "green"
                            : "var(--error-main)",
                        }}
                      ></span>{" "}
                      <span className="txt-grey">
                        {timelineFormatDate(item?.approval_on)}
                      </span>
                    </div>
                    <div
                      className="txt-success px-4 mt-1"
                      style={{
                        color: item?.approval ? "green" : "var(--error-main)",
                      }}
                    >
                      {item?.approval
                        ? `Approved by ${item?.approval_by}`
                        : `Rejected by ${item?.approval_by}`}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {open && (
          <EditFreelancer
            open={open}
            handleClose={handleClose}
            info={freelancerDetail}
            admin={userId.admin}
          />
        )}
        <ConfirmDialog
          isPrompt={false}
          open={openConfirmDialog}
          onClose={setOpenConfirmDialog}
          onConfirm={onConfirmDialog}
          message={`Are you Sure you want to ${
            isActive ? "disable" : "enable"
          } the ${userId.admin ? "admin" : "contractor"}?`}
        />
        <ConfirmDialog
          isPrompt={false}
          open={openDialog}
          onClose={setOpenDialog}
          onConfirm={onConfirm}
          count={selectedVideos.length}
          isDeleteVideo={true}
        />
        <ConfirmDialog
          isPrompt={false}
          open={openDownloadDialog}
          onClose={setOpenDownlaodDialog}
          onConfirm={onDownloadConfirm}
          count={selectedVideos.length}
          isDownloadVideo={true}
        />
        <ConfirmDialog
          isPrompt={true}
          open={openPrompt}
          onClose={setOpenPrompt}
          message={`${
            userId.admin ? "Admin" : "Contractor"
          } data updated successfully`}
        />
        <ConfirmDialog
          isPrompt={true}
          open={openDeletePrompt}
          onClose={setOpenDeletePrompt}
          message={`${
            totalDeletedVideos === 1
              ? `1 Video`
              : `${totalDeletedVideos} Videos`
          } deleted successfully`}
        />

        <ConfirmDialog
          isPrompt={false}
          open={openAccountDeletePrompt}
          onClose={setOpenAccountDeletePrompt}
          onConfirm={onAccountDeleteConfirm}
          message={"Are you sure you want to Delete?"}
        />
      </div>
    </div>
  );
};

export default FreeLancerInfo;
