import { Typography } from "@material-ui/core";
import { Box } from "components";
import React from "react";

export const Help = () => {
  return (
    <Box style={{ borderRadius: ".5rem", background: "white" }} className="p-3">
      <Typography variant="h6">
        Build Version: {process.env.REACT_APP_VERSION}{" "}
      </Typography>
      <Typography variant="h6">
        Date: {process.env.REACT_APP_BUILD_DATE}{" "}
      </Typography>
    </Box>
  );
};

export default Help;
