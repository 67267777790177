import {
  LOG_IN,
  DASHBOARD_DATA,
  ADMIN_DATA,
  FREELANCER_DATA,
  USER_ID,
  SHOW_LOADER,
  PROFILE_DETAILS,
  REFRESH_PROFILE,
  PROFILE,
} from "./actions";

const initialState = {
  profile: {},
  isLoggedIn: false,
  isAdmin: false,
  dashboardData: {},
  adminData: [],
  freelancerData: [],
  userId: { admin: false },
  showLoader: false,
  profileid: null,
  refreshProfile: false,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case LOG_IN: {
      return {
        ...state,
        isLoggedIn: payload?.isLoggedIn,
        isAdmin: payload?.isAdmin,
      };
    }
    case PROFILE: {
      return {
        ...state,
        profile: payload,
      };
    }
    case DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: { ...payload },
      };
    }
    case ADMIN_DATA: {
      return {
        ...state,
        adminData: [...payload],
      };
    }
    case FREELANCER_DATA: {
      return {
        ...state,
        freelancerData: [...payload],
      };
    }
    case USER_ID: {
      return {
        ...state,
        userId: { ...payload },
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        showLoader: payload,
      };
    }
    case PROFILE_DETAILS: {
      return {
        ...state,
        profileid: payload,
      };
    }
    case REFRESH_PROFILE: {
      return {
        ...state,
        refreshProfile: payload,
      };
    }
    default:
      throw new Error();
  }
};

export { reducer, initialState };
