import { useState, useEffect, useContext } from "react";
import styles from "./freelancer.module.scss";
import {
  Button,
  Menu,
  MenuItem,
  InputField,
  UserInfoCard,
  Checkbox,
  ConfirmDialog,
} from "components";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";

import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import AddFreelancer from "./add-freelancer/add-freelancer";
import { Paper, styled, Typography } from "@material-ui/core";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { checkBoolean } from "utils/videoUtils";

const FreeLancer = () => {
  const {
    dispatch,
    state: { isAdmin, freelancerData, showLoader },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const [open, setOpen] = useState(false);
  const [freeLancerDataCopy, setFreeLancerDataCopy] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [filter, setFilter] = useState({
    active: false,
    inactive: false,
    search: "",
  });
  const [sortNameToggle, setSortNameToggle] = useState(false);
  const [sortDOBToggle, setSortDOBToggle] = useState(false);
  const [sortDOJToggle, setSortDOJToggle] = useState(false);
  const [isSortActive, setIsSortActive] = useState(null);
  const [refreshFilter, setRefreshFilter] = useState(false);
  const [paginator, setPaginator] = useState(20);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [opensort, setOpenSort] = useState(null);
  const [openfilter, setOpenfilter] = useState(null);

  const handleSortClick = (event) => {
    setOpenSort(event.currentTarget);
  };
  const handleFilterClick = (event) => {
    setOpenfilter(event.currentTarget);
  };
  const handleClose = (event, reason, showPrompt) => {
    setOpenSort(null);
    setOpenfilter(null);
    setOpen(false);
    showPrompt && setOpenDialog(true);
  };
  useEffect(() => {
    !open && getFreelancerData();
  }, [open]);

  useEffect(() => {
    if (refreshFilter) {
      const { active, inactive, search } = filter;
      filterResults(search, active, inactive);
      setRefreshFilter(false);
    }
  }, [refreshFilter]);

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const onChangeNameSort = () => {
    setIsSortActive("name");
    const newToggle = !sortNameToggle;
    setSortNameToggle(newToggle);
    let sortedData = freeLancerDataCopy.sort((a, b) =>
      newToggle ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );
    setFreeLancerDataCopy(sortedData);
  };
  const onChangeDOBSort = () => {
    setIsSortActive("dob");
    const newToggle = !sortDOBToggle;
    setSortDOBToggle(newToggle);
    let sortedData = freeLancerDataCopy.sort((a, b) =>
      newToggle
        ? new Date(b.dob) - new Date(a.dob)
        : new Date(a.dob) - new Date(b.dob)
    );
    setFreeLancerDataCopy(sortedData);
  };
  const onChangeDOJSort = () => {
    setIsSortActive("doj");
    const newToggle = !sortDOJToggle;
    setSortDOJToggle(newToggle);
    let sortedData = freeLancerDataCopy.sort((a, b) =>
      newToggle
        ? new Date(b.date_joining) - new Date(a.date_joining)
        : new Date(a.date_joining) - new Date(b.date_joining)
    );
    setFreeLancerDataCopy(sortedData);
  };

  const getFreelancerData = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getFreelancerData();
      if (data?.length) {
        storeHandler(actions.FREELANCER_DATA, [...data]);
        setFreeLancerDataCopy([...data]);
      } else {
        setFreeLancerDataCopy([]);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const searchData = (value) => {
    const { active, inactive } = filter;
    setFilter({ ...filter, search: value });
    filterResults(value, active, inactive);
  };

  const updateFilter = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    const filterObj = { ...filter, [name]: value };
    const { active, inactive, search } = filterObj;
    setFilter({ ...filterObj });
    filterResults(search, active, inactive);
  };

  const filterResults = (searchInput, active, inactive) => {
    let filteredData = freelancerData.filter(
      (item) =>
        item.name.toLowerCase().indexOf(searchInput.toLowerCase()) === 0 ||
        item.account_id.toLowerCase().indexOf(searchInput.toLowerCase()) === 0
    );
    if (active || inactive) {
      filteredData = filteredData.filter(
        ({ status }) => (status && active) || (!status && inactive)
      );
    }
    setFreeLancerDataCopy(filteredData);
  };

  const ButtonBox = styled(Button)({
    "&.MuiButton-outlined": {
      color: "black",
      backgroundColor: "#f2f2f2",
      borderColor: "#bfc0c0",
    },
  });

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceHeader} d-flex justify-content-between align-items-center flex-lg-row flex-column`}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Contractors
        </Typography>
        {/* <div className="txt-primary txt-xl txt-500">Contractors</div> */}
        <div>
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            {false && (
              <ButtonWithIcon
                disableRipple
                startIcon={<SortIcon />}
                // onClick={handleFilterClick}
              >
                Sort
              </ButtonWithIcon>
            )}
            {IS_ADMIN && (
              <ButtonWithIcon
                disableRipple
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Add Contractor
              </ButtonWithIcon>
            )}

            <InputField
              variant="filled"
              value={filter?.search || ""}
              placeholder="Search by name or id"
              withAdronment={true}
              onChange={(event) => searchData(event.target.value)}
            />

            {false && (
              <ButtonWithIcon
                disableRipple
                startIcon={<FilterIcon />}
                // onClick={handleFilterClick}
              >
                Filter
              </ButtonWithIcon>
            )}
          </Stack>
          <Menu
            anchorEl={opensort}
            keepMounted
            open={Boolean(opensort)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom" }}
          >
            <MenuItem onClick={onChangeNameSort}>
              <span className={isSortActive === "name" ? "txt-bold" : ""}>
                Name
              </span>
            </MenuItem>
            <MenuItem onClick={onChangeDOBSort}>
              <span className={isSortActive === "dob" ? "txt-bold" : ""}>
                DOB
              </span>
            </MenuItem>
            <MenuItem onClick={onChangeDOJSort}>
              <span className={isSortActive === "doj" ? "txt-bold" : ""}>
                DOJ
              </span>{" "}
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={openfilter}
            keepMounted
            open={Boolean(openfilter)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              {" "}
              <label className="d-flex justify-content-between m-w-100 align-items-center">
                Active{" "}
                <Checkbox
                  color="primary"
                  size="small"
                  className="py-0 pr-0"
                  name="active"
                  onChange={updateFilter}
                />
              </label>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <label className="d-flex justify-content-between m-w-100 align-items-center">
                Inactive{" "}
                <Checkbox
                  color="primary"
                  size="small"
                  className="py-0 pr-0"
                  name="inactive"
                  onChange={updateFilter}
                />
              </label>
            </MenuItem>
          </Menu>
        </div>
      </Paper>
      <div
        className={`${styles.freelancerCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
      >
        {!!freeLancerDataCopy?.length &&
          freeLancerDataCopy.slice(0, paginator).map((item) => (
            <div className="px-3 my-0">
              <UserInfoCard
                item={item}
                admin={false}
                refreshFilter={setRefreshFilter}
                getData={getFreelancerData}
              />
            </div>
          ))}
        {!freeLancerDataCopy?.length && !showLoader && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            There are No
            {filter.active
              ? " active"
              : filter.inactive
              ? " inactive"
              : " "}{" "}
            Contractors!
          </p>
        )}
      </div>
      {freeLancerDataCopy.length > paginator && (
        <p
          className="text-center mt-3 txt-primary txt-bold cursor"
          onClick={() => setPaginator(paginator + 20)}
        >
          Show More Freelancers
        </p>
      )}
      {open && <AddFreelancer open={open} handleClose={handleClose} />}
      <ConfirmDialog
        isPrompt={true}
        open={openDialog}
        onClose={setOpenDialog}
        message={"Contractor Added Successfully"}
      />
    </div>
  );
};

export default FreeLancer;
