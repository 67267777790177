import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as CalendarIcon } from "assets/images/calender.svg";
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
} from "@material-ui/pickers";
// import TextField from 'components/textfield/textfield';
import { TextField } from "@material-ui/core";

import "date-fns";

const textFieldComponent = (TextFieldProps) => (
  <TextField
    {...TextFieldProps}
    variant="filled"
    placeholder="Select Date"
    InputProps={{
      endAdornment: <CalendarIcon className="calendarIcon" />,
      disableUnderline: true,
    }}
  />
);

const DatePicker = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <MuiDatePicker
      //   clearable={props.clearable === false ? false : true}
      format="MMM dd yyyy"
      margin="normal"
      fullWidth
      autoOk
      okLabel={null}
      disableFuture
      TextFieldComponent={textFieldComponent}
      {...props}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;
