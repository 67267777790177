import { Button, IconButton, Stack } from "@mui/material";
import React from "react";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import FullscreenExitSharpIcon from "@mui/icons-material/FullscreenExitSharp";
import AddIcon from "@mui/icons-material/Add";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import ReplayIcon from "@mui/icons-material/Replay";
import smallIcon from "../../../assets/images/small-screen.svg";

const VideoControls = ({
  skipBackWard,
  skipForward,
  isPlaying,
  handleCommentButtonClick,
  handleFullScreen,
  isFullScreen,
  onPlayPause,
  videoRef
}) => {

  const handleReplay = ()=>{
    videoRef.current.currentTime = 0
  }
  return (
    <Stack
      direction="row"
      justifyContent={isFullScreen ? "space-between" : "flex-end"}
      width={"100%"}
    >
      <Stack direction={"row"} ml={{sm:'18rem',md:'28rem',lg:'30rem',xl:'45rem'}} gap={isFullScreen ? 3 : 0}  >
        <IconButton size="small" onClick={skipBackWard} sx={{ color: "white" }}>
          <Replay10Icon />
        </IconButton>
        <IconButton
          size="small"
          onClick={onPlayPause}
          sx={{ color: "white", "&.Mui-disabled": { color: "#6a6f6d" } }}
          disabled={isPlaying}
        >
          <PlayArrowRoundedIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={onPlayPause}
          sx={{ color: "white", "&.Mui-disabled": { color: "#6a6f6d" } }}
          disabled={!isPlaying}
        >
          <PauseRoundedIcon />
        </IconButton>
        <IconButton size="small" onClick={skipForward} sx={{ color: "white" }}>
          <Forward10Icon />
        </IconButton>
      </Stack>
      <Stack direction={"row"}>
        {isFullScreen && (
          <IconButton
            sx={{ color: "white" }}
            size="small"
            onClick={handleReplay}
          >
            <ReplayIcon />
          </IconButton>
        )}
        {isFullScreen === true && (
          <Button
            startIcon={
              <AddIcon sx={{ bgcolor: "#2196f3", borderRadius: "100px" }} />
            }
            sx={{ color: "white" }}
            onClick={handleCommentButtonClick}
          >
            Comment
          </Button>
        )}
        <IconButton
          sx={{ color: "white"}}
          size="small"
          onClick={handleFullScreen}
        >
          {isFullScreen === true ? (
            <FullscreenExitSharpIcon />
          ) : (
            <img src={smallIcon} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default VideoControls;
