// Login APIs
export const LOGIN = "/create?func=login";
export const USER_PROFILE = "/create?func=profile";
export const GET_OTP = "/create?func=forgot";
export const VERIFY_OTP = "/create?func=verify_otp";
export const RESET_PASSWORD = "/create?func=reset";
export const ACTIVATE_PLAYBACK = "/admin/playback";
export const PLAY_BLACK_LIMIT = "/admin/playback";
export const GET_PROFILE = "/create?func=profile";
export const REFRESH_TOKEN = "/refresh/token";

// Admin APIs
export const DASHBOARD = "/";
export const ADMIN = "/get/admins";
export const ADD_ADMIN = "/add/admin";
export const EDIT_ADMIN = "/edit/admin";
export const GET_ADMIN_DETAIL = "/get/admin";
export const ACTIVATE_ADMIN = "/admin/activate";
export const DEACTIVATE_ADMIN = "/admin/deactivate";

// Freelancer APIs
export const FREELANCER = "/?func=get_all_contractors";
export const ADD_FREELANCER = "create?func=add_contractor";
export const EDIT_FREELANCER = "/create?func=edit_contractor";
export const FREELANCER_DETAILS = "create?func=contractor_details";
export const ACTIVATE_FREELANCER = "create?func=activate_contractor";
export const DEACTIVATE_FREELANCER = "create?func=deactivate_contractor";
export const DELETE_FREELANCER = "/delete?func=delete_contractor";

//Video APIs
export const VIDEO_RENAME = "/create?func=rename_video";
export const VIDEO_DELETE = "/video/delete";

//Download APIs
export const STORAGE_VIDEO = "/create?func=storage_videos";
export const GENERATE_DOWNLOAD_URL = "/create?func=generate_download_urls";
export const DELETE_VIDEO = "/delete?func=delete_video";

//User APIs
export const ALL_USER = "/?func=get_all_users";
export const USER_TIMELINE = "/create?func=get_timeline";
export const CREATE_USER = "/create?func=add_users";
export const GET_USER_DETAIL = "/get_user";
export const EDIT_USER = "/create?func=edit_user";
// export const DELETE_USER = "/create?func=edit_user";
export const ACTIVATE_USER = "/create?func=activate_user";
export const DEACTIVATE_USER = "/create?func=deactivate_user";
export const DELETE_USER = "/delete?func=delete_user";

//Gallery
export const GALLERY_YEAR = "/create?func=gallery";
export const GALLERY_MONTH = "/create?func=year";
export const GALLERY_DAYS = "/create?func=month";
export const GALLERY_DAY = "/create?func=day";
export const VIDEO_APPROVE_REJECT = "/create?func=video_approval";
export const ADD_VIDEO_COMMENT = "/create?func=add_comment";
export const EDIT_VIDEO_COMMENT = "/create?func=edit_video_comment";
export const DELETE_VIDEO_COMMENT = "/create?func=delete_video_comment";
export const VIDEO_COMMENT = "/create?func=get_video_comments";
