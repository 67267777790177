import styles from "./prelogin.module.scss";
import { Box } from "components";

const PreLogIn = ({ children }) => {
  return (
    <Box
      className={`px-3 ${styles.loginWrapper}`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box className={`${styles.contentWrap}`}>{children}</Box>
    </Box>
  );
};

export default PreLogIn;
