import { useContext } from "react";
import styles from "./postlogin.module.scss";
import Header from "app/header/header";
import { GlobalContext } from "context";

const PostLogIn = ({ children }) => {
  const { state } = useContext(GlobalContext);
  const { qa_user } = state?.profile || {};

  return (
    <div className={`p-3 ${styles.mainWrap} d-flex flex-column`}>
      <Header />
      {typeof qa_user === "boolean" && <>{children}</>}
    </div>
  );
};

export default PostLogIn;
