import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./DownloadPopover.scss";
import { InputField } from "components";

const getUniqueNames = (data) => {
  const names = [];

  data.forEach((item, index) => {
    try {
      if (typeof item !== "object" || item === null) {
        console.warn(`Item at index ${index} is not an object or is null.`);
        return;
      }

      if (!("contractor" in item)) {
        console.warn(
          `Item at index ${index} does not have a "contractor" field.`
        );
        return;
      }

      names.push(item.contractor);
    } catch (error) {
      console.warn(
        `Error processing item at index ${index}: ${error?.message}`
      );
    }
  });

  const uniqueNames = [...new Set(names)];
  return uniqueNames;
};

function DownloadPopover({
  anchorEl,
  handleClose,
  data,
  handleSearch,
  searchText,
}) {
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleSearchChange = (value) => {
    handleSearch(value);
  };

  const handleToggleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <Box className="Download-popover-root">
      <Popover
        id={"download-popover"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: "20rem",
            },
          },
        }}
      >
        <div className="popover-content" style={{ minWidth: 180 }}>
          <Typography
            variant="subtitle1"
            fontSize={".9rem"}
            sx={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontWeight: "bold",
              cursor: "pointer",
              py: 0.5,
              px: 1,
            }}
            onClick={handleToggleCollapse}
          >
            Contractors
            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Typography>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box display={"flex"} alignItems={"center"} my={0.5}>
              <InputField
                variant="outlined"
                className={`p-0 m-0`}
                placeholder="Search by name or id"
                withAdronment={true}
                value={searchText}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </Box>

            <List onClick={(e) => handleSearchChange(e?.target?.innerText)}>
              {getUniqueNames(data)?.map((contractor, index) => (
                <ListItem key={index} style={{ cursor: "pointer" }}>
                  <ListItemText primary={contractor} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      </Popover>
    </Box>
  );
}

export default DownloadPopover;
