const LOG_IN = "LOG_IN";
const DASHBOARD_DATA = "DASHBOARD_DATA";
const ADMIN_DATA = "ADMIN_DATA";
const FREELANCER_DATA = "FREELANCER_DATA";
const USER_ID = "USER_ID";
const SHOW_LOADER = "SHOW_LOADER";
const PROFILE_DETAILS = "PROFILE_DETAILS";
const REFRESH_PROFILE = "REFRESH_PROFILE";
const PROFILE = "PROFILE";

export {
  LOG_IN,
  DASHBOARD_DATA,
  ADMIN_DATA,
  FREELANCER_DATA,
  USER_ID,
  SHOW_LOADER,
  PROFILE_DETAILS,
  REFRESH_PROFILE,
  PROFILE,
};
