import React from 'react';
import { Snackbar as MuiSnackBar } from '@material-ui/core';
import close from 'assets/images/close.png'



const Snackbar = (props) => {

    const { closeSnackBar } = props;

    const snackbarprops = { ...props };
    delete snackbarprops.closeSnackBar

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeSnackBar(false);
    };

    return (<MuiSnackBar {...snackbarprops}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        action={
            <React.Fragment>
                <img src={close} className='cursor ml-4' width='13px' onClick={handleClose} />
            </React.Fragment>
        }
    />)
}
export default Snackbar;