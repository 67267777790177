import { useState, useContext, useEffect } from "react";
import styles from "../freelancer-info.module.scss";
import avatar from "assets/images/default.svg";
import {
  Button,
  InputField,
  TextField,
  Snackbar,
  DatePicker,
} from "components";
import AuthServices from "api/services/auth-services";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { GlobalContext, actions } from "context";
import { isEmailValid } from "utils/validator";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Stack } from "@mui/material";
import { Typography } from "@material-ui/core";
import { convertImageToBase64WithMetadata } from "utils/videoUtils";

const fieldErrors = {
  name: "Enter Name",
  email: "Enter Email",
  phone: "Enter Phone Number",
  date_joining: "Enter date of joining",
};

const EditFreelancer = ({ handleClose, open, info, admin }) => {
  const [newFreeLancer, setNewFreeLancer] = useState({});
  const [imageFile, setImageFile] = useState();
  const [image, setImage] = useState();
  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const {
    dispatch,
    state: { profileid },
  } = useContext(GlobalContext);
  const { id } = info || {};

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [minLengthInvalid, setMinLengthInvalid] = useState(false);

  useEffect(() => {
    if (info) {
      setNewFreeLancer({ ...info });
      setImage(info.profile);
    }
  }, [info]);

  const validate = () => {
    let isInvalid = false;
    const inputFields = Object.keys(fieldErrors);
    inputFields.forEach((item) => {
      if (!newFreeLancer[item]) {
        isInvalid = true;
        setIsInvalidForm(true);
        return;
      }
    });
    if (!isInvalid) {
      const { email } = newFreeLancer;

      const isValidEmail = isEmailValid(email);
      setInvalidEmail(!isValidEmail);

      if (invalidFileSize || !isValidEmail || minLengthInvalid) {
        setIsInvalidForm(true);
      } else {
        setIsInvalidForm(false);
        updateFreelancer();
        setInvalidEmail(false);
      }
    }
  };

  const editFreeLancer = (key, value) => {
    const val = value;
    const data = {
      ...newFreeLancer,
      [key]: key === "email" ? val.toLowerCase() : val,
    };
    setNewFreeLancer(data);
  };
  const updateFreelancer = async () => {
    const base64Image = imageFile
      ? await convertImageToBase64WithMetadata(imageFile)
      : null;

    // const formData = new FormData();
    const payload = {
      ...newFreeLancer,
      profile: base64Image?.base64,
      content_type: base64Image?.content_type,
      filename: base64Image?.filename,
      ...(newFreeLancer.dob && { dob: dateFormat(newFreeLancer.dob) }),
      ...(newFreeLancer.date_joining && {
        date_joining: dateFormat(newFreeLancer.date_joining),
      }),
    };
    const isCurrentProfile = payload.account_id === profileid;

    // delete payload.status;
    // delete payload.video;
    // delete payload.account_id;
    // delete payload.id;

    // for (let key in payload) {
    //   formData.append(key, payload[key]);
    // }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      !admin
        ? await AuthServices.editFreelancer(id, payload)
        : await AuthServices.editAdmin(id, payload);
      storeHandler(actions.FREELANCER_DATA, []);
      setImage();
      setImageFile();
      handleClose(null, null, true);
      storeHandler(actions.SHOW_LOADER, false);
      storeHandler(actions.REFRESH_PROFILE, isCurrentProfile);
    } catch (err) {
      const { data } = err;
      const { message } = data || {};
      setShowSnackBar(true);
      const content = {
        message: message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const dateFormat = (val) => {
    const [month, date, year] = new Date(val).toLocaleDateString().split("/");
    return `${date}/${month}/${year}`;
  };

  const addImg = (event) => {
    if (
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      console.error("No file selected or invalid event target");
      return;
    }

    const img = event.target.files[0];

    if (!["image/jpeg", "image/jpg"].includes(img.type)) {
      console.error("Selected file is not a JPEG or JPG image");
      setInvalidFileSize(true);
      return;
    }

    // Validate file size
    const fileSizeMB = img.size / 1024 / 1024;
    const isValidFileSize = fileSizeMB <= 2;

    if (isValidFileSize) {
      setImage(URL.createObjectURL(img));
      setImageFile(img);
      setInvalidFileSize(false);
    } else {
      setInvalidFileSize(true);
    }
  };

  const removeImg = () => {
    setImage(null); // Clear the image URL
    setImageFile(null); // Clear the file reference
    setInvalidFileSize(false); // Optionally reset the invalid file size flag
  };

  const textValidator = (event) => {
    const value = event.target.value;
    const invalid = value.length < 3 || value.length > 30;
    setMinLengthInvalid(invalid);
  };

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "1rem",
        },
      }}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogContent>
        <Stack
          mt={".5rem"}
          mb={"1.5rem"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <p
            className="txt-primary txt-xlg m-0 text-center"
            style={{ fontWeight: "500" }}
          >
            Edit {admin ? "Admin" : "Contractor"}
          </p>
          <ButtonWithIcon
            startIcon={<CloseIcon />}
            style={{ padding: ".5rem 1rem" }}
            onClick={handleClose}
          >
            Close
          </ButtonWithIcon>
        </Stack>

        <div className="row pb-4">
          <div className="d-flex flex-wrap  px-0 px-md-3 col-md-8 col-12">
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Name</label>
              <TextField
                value={newFreeLancer?.name || ""}
                variant="filled"
                className={"mr-3"}
                alphanumeric={true}
                fullWidth
                placeholder="Full Name"
                onBlur={textValidator}
                onChange={(event) => editFreeLancer("name", event.target.value)}
                helperText={
                  (isInvalidForm &&
                    !newFreeLancer["name"] &&
                    fieldErrors["name"]) ||
                  (minLengthInvalid &&
                    (newFreeLancer["name"]?.length > 30
                      ? "Enter less than 30 characters"
                      : "Enter minimum 3 characters"))
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Email</label>
              <InputField
                disabled
                value={newFreeLancer?.email || ""}
                variant="filled"
                className={"mr-3"}
                fullWidth
                placeholder="Email Id"
                onBlur={emailValidator}
                onChange={(event) =>
                  editFreeLancer("email", event.target.value)
                }
                helperText={
                  (isInvalidForm &&
                    !newFreeLancer["email"] &&
                    fieldErrors["email"]) ||
                  (invalidEmail && "Enter valid email id")
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">Address</label>
              <InputField
                value={newFreeLancer?.address || ""}
                variant="filled"
                className={"mr-3"}
                fullWidth
                placeholder="Add Address"
                onChange={(event) =>
                  editFreeLancer("address", event.target.value)
                }
                helperText={
                  isInvalidForm &&
                  !newFreeLancer["address"] &&
                  fieldErrors["address"]
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">ZIP</label>
              <TextField
                value={newFreeLancer?.zipcode || ""}
                variant="filled"
                className={"mr-3"}
                type="number"
                fullWidth
                placeholder="City zip code"
                onChange={(event) =>
                  editFreeLancer("zipcode", event.target.value)
                }
                helperText={
                  isInvalidForm &&
                  !newFreeLancer["zipcode"] &&
                  fieldErrors["zipcode"]
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">Phone</label>
              <TextField
                value={newFreeLancer?.phone || ""}
                variant="filled"
                className={"mr-3"}
                type="number"
                fullWidth
                placeholder="Mobile Number"
                onChange={(event) =>
                  editFreeLancer("phone", event.target.value)
                }
                helperText={
                  isInvalidForm &&
                  !newFreeLancer["phone"] &&
                  fieldErrors["phone"]
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">Alternate Phone</label>
              <TextField
                value={newFreeLancer?.alternate_phone || ""}
                variant="filled"
                className={"mr-3"}
                type="number"
                fullWidth
                placeholder="Alternate Mobile Number"
                onChange={(event) =>
                  editFreeLancer("alternate_phone", event.target.value)
                }
                helperText={
                  isInvalidForm &&
                  !newFreeLancer["alternate_phone"] &&
                  fieldErrors["alternate_phone"]
                }
              />
            </div>
            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md">Date of Birth</label>
              <DatePicker
                className="mt-0"
                value={newFreeLancer?.dob || null}
                onChange={(val) => editFreeLancer("dob", val)}
                helperText={
                  isInvalidForm && !newFreeLancer["dob"] && fieldErrors["dob"]
                }
              />
            </div>

            <div className="col-sm-6 col-12 mb-3">
              <label className="txt-bold txt-md required-label">
                Date of Joining
              </label>
              <DatePicker
                className="mt-0"
                value={newFreeLancer?.date_joining || null}
                onChange={(val) => editFreeLancer("date_joining", val)}
                helperText={
                  isInvalidForm &&
                  !newFreeLancer["date_joining"] &&
                  fieldErrors["date_joining"]
                }
              />
            </div>
            {/* {admin && <>
                            <div className='col-sm-6 col-12 mb-3'>
                                <label className='txt-bold txt-md'>Password</label>
                                <TextField variant="outlined" className={'mr-3'} passwordfield={true} fullWidth placeholder="Enter Password" onChange={(event) => editFreeLancer('password', event.target.value)}
                                    helperText={isInvalidForm && !newFreeLancer['password'] && fieldErrors['password'] || isInvalidForm && newFreeLancer?.password?.length < 8 && 'Enter min 8 character'} />
                            </div>
                            <div className='col-sm-6 col-12 mb-3'>
                                <label className='txt-bold txt-md'>Confirm Password</label>
                                <TextField variant="outlined" className={'mr-3'} passwordfield={true} withoutAdornment={true} fullWidth placeholder="Confirm Password" onChange={(event) => editFreeLancer('confirm_password', event.target.value)}
                                    helperText={isInvalidForm && (!newFreeLancer['confirm_password'] && fieldErrors['confirm_password']) || (newFreeLancer['confirm_password'] !== newFreeLancer['password'] && 'Password Mismatch')} />
                            </div>
                        </>} */}
          </div>
          {/* <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
            <div
              className={`d-flex flex-column justify-content-between ${styles.addImg}`}
            >
              <div className="text-center">
                {" "}
                <img
                  src={image ? image : avatar}
                  alt=""
                  className={styles.upload}
                />
              </div>
              <label
                className={`txt-primary ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`}
                for="uploadImg"
              >
                <img src={upload} className="mr-2" alt="upload" />
                Upload Img
              </label>
              <TextField
                className={styles.uploadImg}
                inputProps={{
                  type: "file",
                  accept: "image/*",
                  id: "uploadImg",
                }}
                onChange={addImg}
              />
            </div>
            {(invalidFileSize && (
              <span className="mt-2 txt-error">
                Image size should be less than 2MB
              </span>
            )) ||
              (isInvalidForm && !image && (
                <span className="mt-2 txt-error">Upload Image</span>
              ))}
          </div> */}

          <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
            <div
              className={`d-flex flex-column justify-content-between ${styles.addImg}`}
            >
              <div className="text-center">
                {" "}
                <img
                  src={image ? image : avatar}
                  alt=""
                  className={styles.upload}
                />
              </div>
              {/* <label
                  className={`txt-primary ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`}
                  for="uploadImg"
                >
                  {" "}
                  <img src={upload} className="mr-2" alt="upload" /> Upload Img
                </label>
                <TextField
                  className={styles.uploadImg}
                  inputProps={{
                    type: "file",
                    accept: "image/*",
                    id: "uploadImg",
                  }}
                  onChange={addImg}
                              /> */}

              {!image && (
                <ButtonWithIcon startIcon={<UploadIcon />}>
                  Upload Image
                  <TextField
                    className={styles.uploadImg}
                    inputProps={{
                      type: "file",
                      accept: "image/*",
                      id: "uploadImg",
                    }}
                    onChange={addImg}
                  />
                </ButtonWithIcon>
              )}

              {image && (
                <ButtonWithIcon onClick={removeImg} startIcon={<CloseIcon />}>
                  Remove Image
                </ButtonWithIcon>
              )}
            </div>
            <Typography
              variant="caption"
              className="my-1"
              style={{ color: "var(--text-disabled)" }}
            >
              Image size should be 2MB Max
            </Typography>

            {invalidFileSize && (
              <Typography
                variant="caption"
                style={{ color: "var(--error-main)" }}
              >
                Upload a .jpg or .jpeg image up to 2 MB.
              </Typography>
            )}
            {/* {invalidFileSize && (
                <span className="mt-2 txt-error">
                  Image size should be less than 2MB
                </span>
              )} */}
          </div>
          <div className="col-12 text-center">
            <Button
              variant="contained"
              color="primary"
              className="mt-3"
              onClick={validate}
              style={{
                padding: ".5rem 1rem",
                background:
                  "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={setShowSnackBar}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditFreelancer;
