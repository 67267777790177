import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./gallery-video-player.module.scss";
import defaultThumbnail from "assets/images/videothumbnail.png";
import { Button, Snackbar, TextField, VideoPreviewer } from "components";
import { InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import { actions, GlobalContext } from "context";
import { convertSecondsToHMS } from "utils/videoUtils";
import AuthServices from "api/services/auth-services";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: ".5rem",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: ".5rem",
    },
  },
}));

const sortArrayByProperty = (array, key) => {
  if (!Array.isArray(array)) {
    console.error("First argument must be an array.");
    return [];
  }

  if (typeof key !== "string" || key.trim() === "") {
    console.error("Key must be a non-empty string.");
    return [];
  }

  if (array.length > 0 && typeof array[0] !== "object") {
    console.error("Array must contain objects.");
    return [];
  }

  return array.slice().sort((a, b) => {
    const valueA = Number(a[key]);
    const valueB = Number(b[key]);

    const validValueA = isNaN(valueA) ? 0 : valueA;
    const validValueB = isNaN(valueB) ? 0 : valueB;

    return validValueA - validValueB;
  });
};

const GalleryVideoPlayer = ({ video, thumbnail, approval, id }) => {
  const { dispatch } = useContext(GlobalContext);
  const classes = useStyles();
  const playerRef = useRef(null);
  const [showTimeBtn, setShowTimeBtn] = useState(false);
  const [videoApprove, setVideoApprove] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const roundValue = (time) => {
    const validTime = Number(time);

    if (!isNaN(validTime) && validTime >= 0) {
      const roundedTime = Math.round(validTime);

      setShowTimeBtn(roundedTime);
    } else {
      console.warn("Invalid time value provided:", time);
    }
  };

  const handleShowTimeBtn = () => {
    if (playerRef.current) {
      playerRef?.current?.pause();
      const currentTime = playerRef?.current?.currentTime();
      roundValue(currentTime);
    }
  };

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,
    sources: [
      {
        src: video,
        type: "video/mp4",
      },
    ],
    poster: thumbnail || defaultThumbnail,
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      player.log("player is waiting");
    });

    player.on("dispose", () => {
      player.log("player will dispose");
    });

    player.on("play", () => {
      const currentTime = player.currentTime();
      roundValue(currentTime);
    });

    player.on("pause", () => {
      const currentTime = player.currentTime();
      roundValue(currentTime);
    });

    player.on("seeked", () => {
      const currentTime = player.currentTime();
      roundValue(currentTime);
    });
  };

  const handleApproveRejectVide = async (status) => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      await AuthServices.changeVideoStatus({ id, status });
      setVideoApprove(status);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleGetComments = async () => {
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const { data } = await AuthServices.getVideoComment(id);
      const sortedList = sortArrayByProperty(data, "time_sec");
      setCommentList(sortedList);
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const handleAddComment = async () => {
    const isDuplicate = commentList.some(
      (commentObj) => Number(commentObj.time_sec) == showTimeBtn
    );
    if (comment.length === 0) {
      setErrorMessage("Comment cannot be empty.");
      return;
    } else if (comment.length > 300) {
      setErrorMessage("Comment cannot exceed 300 characters.");
      return;
    } else if (isDuplicate) {
      setErrorMessage(
        "A comment already exists at this time. Please choose a different time."
      );
      return;
    } else {
      setErrorMessage("");
    }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      const payload = {
        video_id: id,
        time_sec: showTimeBtn,
        comment,
      };
      await AuthServices.addVideoComment(payload);
      const newCommentList = [payload, ...commentList];
      const sortedList = sortArrayByProperty(newCommentList, "time_sec");
      setCommentList(sortedList);
      setComment("");
      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      setShowSnackBar(true);
      const content = {
        message: error?.data?.message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
    }
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    setShowTimeBtn(false);
    setComment("");
    setErrorMessage("");
    handleGetComments();
  }, [video]);

  useEffect(() => {
    setVideoApprove(approval);
  }, [approval]);

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.videoPreviewer}`}
      >
        <div className={styles.videoWrapper}>
          <VideoPreviewer
            key={video}
            options={videoJsOptions}
            onReady={handlePlayerReady}
          />
        </div>
      </div>

      <div className={`${styles.commentBody}`}>
        <div className="p-1">
          <TextField
            placeholder="Add Comment.."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onFocus={handleShowTimeBtn}
            classes={{ root: classes.root }}
            variant="outlined"
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "#ededed",
              },
              startAdornment: (
                <InputAdornment position="start">
                  {!!showTimeBtn && (
                    <Button
                      variant="contained"
                      disableRipple={true}
                      style={{
                        padding: ".3rem",
                        fontSize: ".8rem",
                        fontWeight: "200",
                        backgroundColor: "white",
                        color: "black",
                        boxShadow: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      {convertSecondsToHMS(showTimeBtn)}
                    </Button>
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    disabled={!showTimeBtn}
                    onClick={handleAddComment}
                    variant="contained"
                    disableRipple={true}
                    style={{
                      padding: ".3rem",
                      fontSize: ".8rem",
                      fontWeight: "200",
                      backgroundColor: "black",
                      color: "white",
                      boxShadow: "none",
                    }}
                  >
                    Post
                  </Button>
                </InputAdornment>
              ),
            }}
            helperText={errorMessage}
          />
        </div>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={"1rem"}
          className=" my-3"
        >
          {(videoApprove === true || videoApprove === null) && (
            <Button
              disabled={videoApprove === true}
              onClick={(e) => {
                handleApproveRejectVide(true);
              }}
              style={{
                padding: ".3rem 1rem",
                borderRadius: "2rem",
                fontSize: ".8rem",
                fontWeight: "200",
                backgroundColor: "black",
                color: "white",
                boxShadow: "none",
                width: "5rem",
              }}
            >
              {videoApprove === true ? "Approved" : "Approve"}
            </Button>
          )}

          {(videoApprove === false || videoApprove === null) && (
            <Button
              disabled={videoApprove === false}
              variant="contained"
              onClick={() => handleApproveRejectVide(false)}
              style={{
                padding: ".3rem 1rem",
                width: "5rem",
                borderRadius: "2rem",
                fontSize: ".8rem",
                fontWeight: "200",
                backgroundColor: "var(--error-main)",
                color: "white",
                boxShadow: "none",
              }}
            >
              {videoApprove === false ? "Rejected" : "Reject"}
            </Button>
          )}
        </Stack>

        <Stack
          direction="column"
          maxHeight={"15rem"}
          style={{ overflowY: "scroll" }}
        >
          {!!commentList?.length && (
            <Stack className={`${styles.allComment}`}>
              {commentList?.map(({ id, comment, time_sec }) => (
                <Stack className={`${styles.comment}`}>
                  <Typography variant="caption">
                    {convertSecondsToHMS(time_sec, false)}
                  </Typography>
                  <Typography variant="caption">{comment}</Typography>
                </Stack>
              ))}
            </Stack>
          )}
        </Stack>

        <Snackbar
          open={showSnackBar}
          message={snackbarContent?.message || ""}
          className={snackbarContent?.class || ""}
          autoHideDuration={4000}
          closeSnackBar={setShowSnackBar}
        />
      </div>
    </>
  );
};

export default GalleryVideoPlayer;
