import { useState, useEffect, useContext } from "react";
import styles from "./admin.module.scss";
import {
  InputField,
  UserInfoCard,
  ConfirmDialog,
  Checkbox,
  Menu,
  MenuItem,
} from "components";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as FilterIcon } from "assets/images/filter.svg";
import AuthServices from "api/services/auth-services";
import AddAdmin from "./add-admin/add-admin";
import { GlobalContext, actions } from "context";
import { ReactComponent as SortIcon } from "assets/images/sort.svg";
import { Paper, Typography } from "@material-ui/core";
import { Stack } from "@mui/material";
import ButtonWithIcon from "components/IconButton/icon-button";

const Admin = () => {
  const [open, setOpen] = useState(false);
  const { dispatch, state } = useContext(GlobalContext);
  const { adminData, showLoader } = state;
  const [adminDataCopy, setAdminDataCopy] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshAdmin, setRefreshAdmin] = useState(false);

  const [filter, setFilter] = useState({
    active: false,
    inactive: false,
    search: "",
  });
  const [openfilter, setOpenfilter] = useState(null);
  const [refreshFilter, setRefreshFilter] = useState(false);

  useEffect(() => {
    if (!adminData?.length) {
      getAdminData();
    } else {
      setAdminDataCopy(adminData);
    }
    !open && getAdminData();
  }, [open]);

  useEffect(() => {
    if (refreshAdmin) {
      getAdminData();
      setRefreshAdmin(false);
    }
  }, [refreshAdmin]);

  useEffect(() => {
    if (refreshFilter) {
      const { active, inactive, search } = filter;
      filterResults(search, active, inactive);
      setRefreshFilter(false);
    }
  }, [refreshFilter]);

  const storeHandler = (type, payload) => dispatch({ type, payload });
  const getAdminData = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const { data } = await AuthServices.getAllUsers();
      if (data?.length) {
        storeHandler(actions.ADMIN_DATA, [...data]);
        setAdminDataCopy([...data]);
        setRefreshFilter(true);
      }
      storeHandler(actions.SHOW_LOADER, false);
    } catch (err) {
      console.log(err);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason, showPrompt) => {
    setOpen(false);
    setOpenfilter(null);
    showPrompt && setOpenDialog(true);
  };
  // const searchData = (value) => {
  //     const filteredData = adminData.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.id.toLowerCase().indexOf(value.toLowerCase()) > -1);
  //     setAdminDataCopy(filteredData)
  // }

  const searchData = (value) => {
    const { active, inactive } = filter;
    setFilter({ ...filter, search: value });
    filterResults(value, active, inactive);
  };

  const updateFilter = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    const filterObj = { ...filter, [name]: value };
    const { active, inactive, search } = filterObj;
    setFilter({ ...filterObj });
    filterResults(search, active, inactive);
  };

  const filterResults = (searchInput, active, inactive) => {
    let filteredData = adminData.filter(
      (item) =>
        item.name.toLowerCase().indexOf(searchInput.toLowerCase()) === 0 ||
        item.account_id.toLowerCase().indexOf(searchInput.toLowerCase()) === 0
    );
    if (active || inactive) {
      filteredData = filteredData.filter(
        ({ status }) => (status && active) || (!status && inactive)
      );
    }
    setAdminDataCopy(filteredData);
  };

  const handleFilterClick = (event) => {
    setOpenfilter(event.currentTarget);
  };

  return (
    <div className="pt-4">
      <Paper
        style={{
          borderRadius: "1rem",
          boxShadow:
            "-10px -10px 50px 0px #FFF inset, 10px 10px 50px 0px rgba(211, 211, 211, 0.25) inset, -18px -18px 48.5px 0px #FFF, 18px 18px 50px 0px rgba(173, 173, 173, 0.29)",
          border: "none",
        }}
        variant="outlined"
        className={`${styles.freelanceHeader} d-flex justify-content-between align-items-center flex-md-row flex-column `}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "2.3rem", fontWeight: "600" }}
        >
          Users
        </Typography>
        <div>
          <Stack direction={"row"} gap={"1rem"} pr={".5rem"}>
            {false && (
              <ButtonWithIcon
                disableRipple
                startIcon={<SortIcon />}
                // onClick={handleFilterClick}
              >
                Sort
              </ButtonWithIcon>
            )}

            <ButtonWithIcon
              disableRipple
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add User
            </ButtonWithIcon>

            <InputField
              variant="filled"
              placeholder="Search by name or id"
              withAdronment={true}
              onChange={(event) => searchData(event.target.value)}
            />

            {false && (
              <ButtonWithIcon
                disableRipple
                startIcon={<FilterIcon />}
                // onClick={handleFilterClick}
              >
                Filter
              </ButtonWithIcon>
            )}
          </Stack>
          <Menu
            anchorEl={openfilter}
            keepMounted
            open={Boolean(openfilter)}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              {" "}
              <label className="d-flex justify-content-between m-w-100 align-items-center">
                Active{" "}
                <Checkbox
                  color="primary"
                  size="small"
                  className="py-0 pr-0"
                  name="active"
                  onChange={updateFilter}
                />
              </label>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <label className="d-flex justify-content-between m-w-100 align-items-center">
                Inactive{" "}
                <Checkbox
                  color="primary"
                  size="small"
                  className="py-0 pr-0"
                  name="inactive"
                  onChange={updateFilter}
                />
              </label>
            </MenuItem>
          </Menu>
        </div>
      </Paper>
      <div
        className={`${styles.freelancerCardWrapper} py-4 d-flex flex-wrap justify-content-center justify-content-md-start`}
      >
        {!!adminDataCopy?.length &&
          adminDataCopy.map((item) => (
            <div className="px-3 my-0">
              <UserInfoCard
                item={item}
                admin={true}
                refreshAdmin={setRefreshAdmin}
                getData={getAdminData}
              />
            </div>
          ))}
        {!adminDataCopy?.length && !showLoader && (
          <p className="txt-xlg txt-primary flex-grow-1 text-center">
            There are No
            {filter.active
              ? " active"
              : filter.inactive
              ? " inactive"
              : " "}{" "}
            Users!
          </p>
        )}
      </div>
      {open && <AddAdmin open={open} handleClose={handleClose} />}
      <ConfirmDialog
        isPrompt={true}
        open={openDialog}
        onClose={setOpenDialog}
        message={"User Added Successfully"}
      />
    </div>
  );
};

export default Admin;
