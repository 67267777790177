import { Stack } from "@mui/material";
import DownloadBox from "../DownloadBox";
import { useContext, useEffect, useRef, useState } from "react";
import { getDataFromLocalStorage } from "utils/videoUtils";
import { Paper, Typography } from "@material-ui/core";
import { GlobalContext } from "context";

const DownloadSection = () => {
  const [data, setData] = useState({});
  const {
    state: { showLoader },
  } = useContext(GlobalContext);

  const timeRef = useRef(null);
  useEffect(() => {
    timeRef.current = setInterval(() => {
      const video_group = getDataFromLocalStorage("video_group");
      setData(video_group);
    }, 1000);

    return () => {
      clearInterval(timeRef?.current);
    };
  }, []);

  if (!Object.keys(data)?.length && !showLoader) {
    return (
      <>
        <div>
          {true && (
            <Typography variant="h4" className="mt-5 text-center">
              No Data Found!
            </Typography>
          )}
        </div>
      </>
    );
  }

  return (
    <Paper variant="outlined" style={{ borderRadius: ".5rem", border: "none" }}>
      <Stack
        direction={"column"}
        gap={1}
        maxHeight={"250px"}
        my={2}
        sx={{ overflowY: "scroll" }}
      >
        {Object.values(data)?.map((item) => (
          <DownloadBox data={item} />
        ))}
      </Stack>
    </Paper>
  );
};

export default DownloadSection;
