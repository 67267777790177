import { Button } from "components";

const { makeStyles } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f2f0f0",
    border: "1px solid #D0D0D0",
    fontSize: ".9rem",
    "& .MuiButton-startIcon": {
      marginLeft: ".1rem",
      width: "1rem",
    },

    "&:hover .MuiButton-startIcon": {
      marginLeft: ".1rem",
      "& svg": {
        fill: "var(--error-main)",
      },
    },
  },
}));

function ButtonWithIcon(props) {
  const classes = useStyles();

  return <Button {...props} classes={{ root: classes.root }} />;
}

export default ButtonWithIcon;
