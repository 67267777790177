import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./year.module.scss";
import { Stack } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import VideocamIcon from "@mui/icons-material/Videocam";

const YearCard = ({ data, getData }) => {
  const { contractors, videos, type, key } = data || {};

  const handleClick = () => {
    getData([{ value: key, key: type }]);
  };

  return (
    <div
      className={`${styles.freeLancerCard} p-1 d-flex mb-4`}
      onClick={handleClick}
    >
      <div className="flex-grow-1" style={{ color: "white" }}>
        <Typography
          variant="h1"
          style={{
            fontWeight: "bolder",
            textAlign: "start",
            lineHeight: "6rem",
            color: "var(--error-main)",
            textTransform: "capitalize",
            padding: "0 .5rem",
          }}
        >
          {key}
        </Typography>
        <Stack className="pt-0 px-3">
          <Stack direction="row" alignItems="center" gap={".5rem"}>
            <PeopleAltIcon style={{ fontSize: "2rem" }} />
            <Typography variant="body2">
              <span style={{ color: "var(--error-main)", fontSize: ".7rem" }}>
                {contractors || "0"}
              </span>{" "}
              Contractors
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" gap={".5rem"}>
            <VideocamIcon style={{ fontSize: "2rem" }} />
            <Typography variant="body2">
              <span style={{ color: "var(--error-main)", fontSize: ".7rem" }}>
                {videos || "0"}
              </span>{" "}
              Video
            </Typography>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};

export default YearCard;
