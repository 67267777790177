import AuthServices from "api/services/auth-services";
import axios from "axios";
import { getDataFromLocalStorage } from "utils/videoUtils";

export const simulatePostApi = async (video) => {
  try {
    if (!video?.id) {
      throw new Error("video ID is not present");
    }

    const data = await AuthServices.generateDownloadURL({ id: [video.id] });
    const { urls } = data || {};
    if (!urls?.length) {
      throw new Error("Getting Empty Video URL");
    }
    return urls;
  } catch (error) {
    console.log(error);
    throw new Error();
  }
};

const videoDownloader = async (video) => {
  try {
    const res_url = await simulatePostApi(video);

    if (!res_url?.length) {
      throw new Error("URL Length ls 0");
    }

    const response = await axios({
      url: res_url?.[0],
      method: "GET",
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const current_download = JSON.stringify({
          ...video,
          total: total,
          loaded: loaded,
        });
        localStorage.setItem("current_download", current_download);

        const video_group =
          getDataFromLocalStorage("video_group")?.[video?.group_id] || "";
        if (video_group) {
          video_group?.forEach((item, ind) => {
            if (item?.id === video?.id) {
              video_group[ind] = {
                ...video,
                total: total,
                loaded: loaded,
              };
            }
          });
        }

        let x = getDataFromLocalStorage("video_group");
        x[video?.group_id] = video_group;
        localStorage.setItem("video_group", JSON.stringify(x));
      },
    });

    const blob = new Blob([response.data]);
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${video?.file_name}.mp4`;
    downloadLink.click();

    window.URL.revokeObjectURL(downloadLink.href);
    localStorage.setItem("current_download", "");

    const video_list = getDataFromLocalStorage("video_list");
    if (video_list && video_list?.length > 0) {
      video_list?.shift();
      localStorage.setItem("video_list", JSON.stringify(video_list));
    }
  } catch (error) {
    console.error("Error downloading video:", error);
    const video_list = getDataFromLocalStorage("video_list");
    if (video_list && video_list?.length > 0) {
      video_list?.shift();
      localStorage.setItem("video_list", JSON.stringify(video_list));
    }
  }
};

export default videoDownloader;
