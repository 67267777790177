import { createTheme } from "@mui/material";
import infoIcon from "assets/images/info.png";
const appTheme = {
  primary: "#C20000",
  white: "#ffffff",
  textColor: "#5e5e5e",
  greyLt: "#b9b9b9",
  primaryLt: "#FF0000",
  greyLt2: "#D1D1D1",
  error: "#ff5722",
  success: "#49A800",
  pgHeaderColor: "#fbfbfb",
};
const SuccessIcon = {
  xlg: 20,
  xl: 16,
  lg: 14,
  sm: 12,
};

const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: appTheme.primary,
    },
    // background: {
    //   paper: "#FFFFFF",
    //   default: "#FAFAFA",
    // },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: "14px",
          ["@media(min-width: 1200px)"]: {
            fontSize: "16px",
          },
          ["@media(min-width: 1600px)"]: {
            fontSize: "18px",
          },
          ["@media(min-width: 1920px)"]: {
            fontSize: "20px",
          },
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        // fontSize: "1rem",
        // "&.btn-lg": {
        //   width: "100%",
        //   borderRadius: "0.5rem",
        //   fontSize: "1.3rem",
        // },
      },
      containedPrimary: {
        // color: appTheme.white,
        // fontWeight: "bold",
      },
      outlinedPrimary: {
        // backgroundColor: appTheme.primary,
        // borderRadius: "0.5rem",
        // "&:hover": {
        //   backgroundColor: "#f2f2f2",
        //   borderColor: "#bfc0c0",
        // },
        // color: "black",
        // backgroundColor: "#f2f2f2",
        // borderColor: "#bfc0c0",
        // "&.outline-none": {
        //   borderColor: "transparent",
        //   backgroundColor: "transparent",
        //   "&:hover": {
        //     borderColor: "transparent",
        //     backgroundColor: "transparent",
        //   },
        // },
      },
      // sizeSmall: {
      //   minWidth: "auto",
      //   ["@media(max-width: 567px)"]: {
      //     padding: "3px",
      //   },
      // },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: appTheme.white,
        border: "none",
        borderBottom: "1.5px solid #D0D0D0",
        borderRadius: "0rem !important",
        fontSize: "0.8rem",
        "&:hover": {
          backgroundColor: appTheme.white,
        },
        "&$focused": {
          backgroundColor: appTheme.white,
          border: "none",
          borderBottom: "1.5px solid var(--error-main)",
        },
      },
      input: {
        padding: ".8rem .5rem",
      },
    },
    MuiFormHelperText: {
      root: {
        marginLeft: "0 !important",
        color: "var(--error-light)",
        // fontWeight: "bold",
      },
    },
    // MuiTextField: {
    //   root: {
    //     "& .MuiFormLabel": {
    //       color: appTheme.textColor,
    //     },
    //     "& .MuiFormLabel-filled": {
    //       color: appTheme.textColor,
    //     },
    //     "&.filled-input": {
    //       backgroundColor: "#f9f9f9",
    //       color: appTheme.textColor,
    //       "& ::placeholder": {
    //         color: appTheme.greyLt,
    //         opacity: 1,
    //       },
    //     },
    //     "&.otp": {
    //       width: "2rem !important",
    //       "& .MuiFilledInput-input": {
    //         padding: "0.5rem",
    //       },
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      root: {
        borderRadius: ".3rem",
        fontSize: "0.8rem",
        // color: appTheme.textColor,
        // "&:hover .MuiOutlinedInput-notchedOutline": {
        //   borderColor: appTheme.greyLt,
        // },
        "& ::placeholder": {
          color: appTheme.greyLt,
          opacity: 1,
        },
      },
      input: {
        padding: ".9rem .6rem",
        // paddingTop: "0.8rem",
        // paddingBottom: "0.8rem",
      },
      notchedOutline: {
        borderColor: appTheme.greyLt,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "0.5rem",
        borderTopRightRadius: "0.2rem",
        padding: "0.2rem 0.8rem",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.6rem",
        minWidth: "5rem",
        color: appTheme.primary,
        borderBottom: `0.1rem solid ${appTheme.primaryLt}`,
        "&:last-child": {
          border: "none",
        },
        "&.logout": {
          minWidth: "2rem",
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: appTheme.primary,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        width: "100%",
        borderRadius: "1rem",
        "&.MuiPickersModal-dialogRoot": {
          width: "auto",
          borderRadius: "0.5rem",
        },
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: "0px 0px 6px rgba(0,0,0,0.2)",
      },
    },
    MuiSnackbar: {
      root: {
        "&.error .MuiSnackbarContent-root": {
          backgroundColor: appTheme.error,
          paddingLeft: "2.5rem",
          paddingRight: "1.5rem",
          background: `url(${infoIcon}) .75rem center no-repeat`,
          backgroundSize: "1.2rem",
        },
        "&.success .MuiSnackbarContent-root": {
          backgroundColor: appTheme.success,
          paddingLeft: "2.5rem",
          paddingRight: "1.5rem",
          background: `url(${infoIcon}) .75rem center no-repeat`,
          backgroundSize: "1.2rem",
        },
      },
    },
    MuiTextField: {
      root: {
        "& .MuiOutlinedInput-root": {
          backgroundColor: "white",
          border: "1px solid #D0D0D0",
          fontSize: ".8rem",
          "&:hover:not(.Mui-focused):not(.Mui-error) fieldset": {
            borderWidth: 1,
            borderColor: "#D0D0D0",
          },
          "& fieldset": {
            borderWidth: 1,
            borderColor: "#D0D0D0",
          },
          "&.Mui-focused fieldset": {
            borderWidth: 1,
            borderColor: "#D0D0D0",
          },
          "&.Mui-error fieldset": {
            borderWidth: 1,
            borderColor: "#D0D0D0",
          },
          "&.Mui-disabled fieldset": {
            borderWidth: 1,
            borderColor: "#D0D0D0",
          },
        },
      },
    },
  },
});

const setSelectedCssVariables = (palette, keys, spacingValues) => {
  keys.forEach((key) => {
    if (palette[key]) {
      for (const [colorKey, colorValue] of Object.entries(palette[key])) {
        const variableName = `--${key}-${colorKey}`;
        document.documentElement.style.setProperty(variableName, colorValue);
      }
    }
  });

  // spacingValues.forEach((value) => {
  //   const variableName = `--spacing-${value}`;
  //   const spacingValue = theme.spacing(value / 4);
  //   document.documentElement.style.setProperty(variableName, spacingValue);
  // });
};

const selectPalette = () => {
  const palette = theme.palette;
  if (palette.mode === "dark") {
    palette.background.default = "#2B2B2B";
    palette.background.paper = "#141414";
  }
  const arr = ["primary", "secondary", "background", "error", "text"];
  const spacingValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  setSelectedCssVariables(palette, arr, spacingValues);
};

selectPalette();

export default theme;
