import { Box, Slider, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import video_url from "../../../assets/video.mp4";
import CommentDialog from "./CommentDialog";
import VideoControls from "./VideoControls";

const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  if (time) {
    return (
      String(hours).padStart(2, "0") +
      ":" +
      String(minutes).padStart(2, "0") +
      ":" +
      String(seconds).padStart(2, "0")
    );
  } else {
    return "00:00:00";
  }
};

const sliderStyles = {
  paddingBottom: 0,
  "& .MuiSlider-mark": {
    backgroundColor: "yellow",
    height: 8,
    width: 8,
    borderRadius: "50%",
  },
  "& .MuiSlider-markLabel": {
    color: "white",
    fontSize: "0.75rem",
    maxWidth: "250px",
    textAlign: "center",
    whiteSpace: "break-spaces",
    textWrap: "wrap",
    top: "-2rem",

    backgroundColor: "#3c3c3c",
    padding: "2px 4px",
  },
};

const CustomVideoPlayer = () => {
  const videoRef = useRef(null);
  const snapShotRef = useRef(null);
  const secondVideoRef = useRef(null);
  const [snapshots, setSnapShots] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isopenDialogue, setIsOpenDialogue] = useState(false);
  const [comments, setComments] = useState([]);

  //
  const addComment = (comment) => {
    setComments((pre) => [...pre, comment]);
  };

  const updateComment = (editIndex, editText) => {
    const updatedComments = [...comments];

    updatedComments[editIndex] = {
      ...updatedComments[editIndex],
      text: editText,
    };

    setComments(updatedComments);
  };

  const deleteComment = (editIndex) => {
    const RemainComments = comments.filter((el, index) => editIndex !== index);
    setComments(RemainComments);
  };
  const onSliderHover = (e) => {
    let hoverTime = (
      ((e.clientX - e.target.offsetLeft) / e.target.clientWidth) *
      duration
    ).toFixed(2);

    if (hoverTime < 0) {
      hoverTime = 0;
    }
    secondVideoRef.current.currentTime = hoverTime;

    shoot(secondVideoRef.current);

    if (snapShotRef) {
      snapShotRef.current.style.left = e.clientX - 75 + "px";
    }
  };

  const shoot = (video) => {
    let canvas = capture(video);
    setSnapShots(canvas.toDataURL());
  };
  const capture = (video) => {
    let w = video.videoWidth;
    let h = video.videoHeight;
    let canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    ctx?.drawImage(video, 0, 0, w, h);
    return canvas;
  };

  const handleMouseOver = (event) => {
    const closestMark = event.target.closest(".MuiSlider-mark");

    if (closestMark) {
      const closestMarkIndex = closestMark.getAttribute("data-index");
      const markLabels = document.querySelectorAll(".MuiSlider-markLabel");
      markLabels[closestMarkIndex].style.display = "block";
    }
  };

  const handleMouseOut = (event) => {
    setSnapShots("");
    const markLabels = document.querySelectorAll(".MuiSlider-markLabel");
    if (markLabels) {
      markLabels.forEach((el) => (el.style.display = "none"));
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleDialogueClose = () => {
    setIsOpenDialogue(false);
  };

  const handleSliderChange = (event, newValue) => {
    videoRef.current.currentTime = newValue;
  };

  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };
  const skipBackWard = () => {
    videoRef.current.currentTime -= 10;
  };
  const handlePlayAndPause = () => {
    if (videoRef.current?.paused) {
      videoRef.current?.play();
      setIsPlaying(true);
    } else {
      videoRef.current?.pause();
      setIsPlaying(false);
    }
  };
  //
  const handleFullScreen = () => {
    if (isFullScreen) {
      setIsFullScreen(false);
    } else {
      setIsFullScreen(true);
    }
  };

  const handleCommentButtonClick = () => {
    videoRef.current.pause();
    setIsPlaying(false);
    setIsOpenDialogue(true);
  };

  const handleSeekToTimestamp = (timestamp) => {
    setCurrentTime(timestamp);
  };

  useEffect(() => {
    if (videoRef.current) {
      const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
      };

      const handleVideoEnd = () => {
        videoRef.current.currentTime = 0;
        setCurrentTime(0);
        setIsPlaying(false);
      };

      videoRef.current?.addEventListener(
        "loadedmetadata",
        handleLoadedMetadata
      );

      videoRef.current?.addEventListener("ended", handleVideoEnd);

      return () => {
        videoRef.current?.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
        videoRef.current?.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, []);

  console.log("snapshot", snapshots);
  return (
    <Box
      className={isFullScreen && "video-Player-FullScreen"}
      sx={{
        backgroundColor: isFullScreen ? "black" : "transparent",
        position: "relative",
        width: { lg: isFullScreen ? "100vw" : "50vw" },
        height: {
          sm: isFullScreen ? "100vh" : "fit-content",
          md: isFullScreen ? "100vh" : "fit-content",
          lg: isFullScreen ? "100vh" : "fit-content",
          xl: isFullScreen ? "100vh" : "fit-content",
        },
      }}
    >
      <video
        className="videoplayer"
        src={video_url}
        onTimeUpdate={handleTimeUpdate}
        ref={videoRef}
      />
      <Box className="video-buttons-container">
        <Stack direction="row" alignItems="center" spacing={1} width={"100%"}>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            {formatTime(currentTime)}
          </Typography>

          <Slider
            className="slider"
            value={currentTime}
            time={currentTime}
            max={videoRef.current?.duration}
            size="small"
            onChange={handleSliderChange}
            sx={sliderStyles}
            marks={comments.map((comment) => ({
              value: comment.time,
              label: comment.text,
            }))}
            markLabel={(mark) => (
              <Tooltip
                title={mark.label}
                placement="top"
                color="primary"
                arrow
              ></Tooltip>
            )}
            onMouseMove={onSliderHover}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />

          <div
            ref={snapShotRef}
            className="snapshotContainer"
            style={
              !isFullScreen ? { opacity: "0", visibility: "hidden" } : null
            }
          >
            <img src={snapshots} className="snapshotImg" />
          </div>

          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            {formatTime(duration)}
          </Typography>
        </Stack>

        <VideoControls
          onPlayPause={handlePlayAndPause}
          isFullScreen={isFullScreen}
          isPlaying={isPlaying}
          handleCommentButtonClick={handleCommentButtonClick}
          skipBackWard={skipBackWard}
          skipForward={skipForward}
          handleFullScreen={handleFullScreen}
        />
      </Box>
      <CommentDialog
        open={isopenDialogue}
        handleClose={handleDialogueClose}
        currentTime={currentTime}
        comments={comments}
        addComment={addComment}
        seekToTimestamp={handleSeekToTimestamp}
        handleCommentUpdate={updateComment}
        handleDeleteComment={deleteComment}
      />
      <video
        className="secondVideoElement"
        src={video_url}
        ref={secondVideoRef}
      ></video>
    </Box>
  );
};

export default CustomVideoPlayer;
