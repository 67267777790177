import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadGif from "assets/images/downloadgif.gif";
import { formatBytes } from "utils/videoUtils";
import { Paper } from "@material-ui/core";
const CurrentDownloadBox = ({ label, min, max, completed }) => {
  return (
    <Paper variant="outlined">
      <Stack
        direction="row"
        alignItems="center"
        gap={3}
        borderRadius={0.5}
        px={2}
        py={1}
      >
        <Typography variant="subtitle1" fontSize={".9rem"} fontWeight={600}>
          {label}
        </Typography>
        <Typography fontSize={".7rem"}>
          {formatBytes(min) || "--"}/
          <span style={{ color: "red" }}>{formatBytes(max) || "--"}</span>
        </Typography>
        <img src={DownloadGif} width={"18px"} alt="" />
      </Stack>
    </Paper>
  );
};

export default CurrentDownloadBox;
