import { PreLogIn, PostLogIn } from "layout";
import { PrivateRoute, PublicRoute } from "routes";
import { useContext, useEffect, useRef } from "react";
import { Switch } from "react-router-dom";
import { GlobalContext, actions } from "context";

import Login from "./login/login";
import ForgotPassword from "./forget-passsword/forget-password";
import ChangePassword from "./change-password/change-password";
import Dashboard from "app/dashboard/dashboard";
import FreeLancer from "app/freelancer/freelancer";
import Admin from "app/admin/admin";
import FreeLancerInfo from "app/freelancer-info/freelancer-info";
import CircularProgress from "@material-ui/core/CircularProgress";
import Gallery from "./Gallery/Gallery";
import videoPlayer from "./video-player";
import Help from "./help/help";
import Downloads from "./Downloads/Downloads";
import { getDataFromLocalStorage } from "utils/videoUtils";
import videoDownloader from "./downloadfn";

function App() {
  const timeRef = useRef(null);
  const {
    state: { showLoader },
    dispatch,
  } = useContext(GlobalContext);

  useEffect(() => {
    validateLogIn();
  }, []);

  const validateLogIn = () => {
    try {
      const token = localStorage.getItem("access_token");
      const isAdmin = localStorage.getItem("isAdmin");

      if (!token || (isAdmin !== "true" && isAdmin !== "false")) {
        handleLogout();
        return;
      }

      const isLoggedIn = !!token;
      const adminStatus = isAdmin === "true";

      storeHandler(actions.LOG_IN, {
        isLoggedIn,
        isAdmin: adminStatus,
      });
    } catch (error) {
      console.error("Error validating login:", error);
      handleLogout();
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("isAdmin");
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  useEffect(() => {
    localStorage.removeItem("current_download");
    const handle_download = () => {
      try {
        const video_list = getDataFromLocalStorage("video_list");
        const current_download = getDataFromLocalStorage("current_download");
        if (!current_download && video_list?.length) {
          videoDownloader(video_list?.[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    timeRef.current = setInterval(handle_download, 5000);

    return () => {
      clearInterval(timeRef?.current);
    };
  }, []);

  return (
    <div>
      {showLoader && (
        <div className="loader" style={{ zIndex: 9999999 }}>
          <CircularProgress />
        </div>
      )}
      <Switch>
        <PublicRoute path="/login" component={Login} layout={PreLogIn} />
        <PublicRoute path="/help" component={Help} layout={PreLogIn} />
        <PublicRoute
          path="/forgot-password"
          component={ForgotPassword}
          layout={PreLogIn}
        />
        <PrivateRoute
          path="/contractors"
          component={FreeLancer}
          layout={PostLogIn}
        />
        <PrivateRoute path="/users" component={Admin} layout={PostLogIn} />
        <PrivateRoute
          path="/user-info"
          component={FreeLancerInfo}
          layout={PostLogIn}
        />
        <PrivateRoute
          path="/video"
          component={videoPlayer}
          layout={PostLogIn}
        />
        <PrivateRoute
          path="/video-download"
          component={Downloads}
          layout={PostLogIn}
        />
        <PrivateRoute path="/gallery" component={Gallery} layout={PostLogIn} />
        <PrivateRoute path="/" component={Dashboard} layout={PostLogIn} />
      </Switch>
    </div>
  );
}

export default App;
