import axios from "axios";
import AuthServices from "./services/auth-services";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

const onSuccess = (response) => Promise.resolve(response);
const onError = async ({ response }) => {
  try {
    // Ensure `response` and `response.data` are defined
    const { data } = response || {};
    const { message } = data || {};

    // Check if the error message indicates token expiration
    if (message === "Token has expired") {
      // Optionally call a refresh token service
      // const newToken = await AuthServices.refreshToken();

      // Clear local storage and reload the page
      localStorage.clear();
      window.location.reload();

      // Log the response data for debugging
      console.log(data);
    }

    // Reject the promise with the response to propagate the error
    return Promise.reject(response);
  } catch (error) {
    // Handle any errors that occur during the execution of onError
    console.error("An error occurred in the onError handler:", error);

    // Optionally reject with the error if needed
    return Promise.reject(error);
  }
};

httpClient.interceptors.request.use((req) => {
  req.headers = {};
  if (req.url.indexOf("/video/delete") > -1) {
    req.headers["Content-Type"] = "application/json";
  }
  if (req.url === "/refresh/token") {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "refresh_token"
    )}`;
  } else {
    req.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "access_token"
    )}`;
  }
  return req;
});
httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
