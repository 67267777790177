import { Box, Button, Stack } from "@mui/material";
import CurrentDownloadBox from "../CurrentDownloadBox";
import { useEffect, useRef, useState } from "react";
import { getDataFromLocalStorage } from "utils/videoUtils";

const CurrentDownload = () => {
  const [data, setData] = useState([]);

  const timeRef = useRef(null);
  const initialItemCount = 4;
  const [showAll, setShowAll] = useState(false);
  const visibleItems = showAll ? data?.length : initialItemCount;
  const itemsToShow = data?.slice(0, visibleItems);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    timeRef.current = setInterval(() => {
      const current_download = getDataFromLocalStorage("current_download");
      const video_group = getDataFromLocalStorage("video_group");
      setData(video_group[current_download?.group_id] || []);
    }, 1000);

    return () => {
      clearInterval(timeRef?.current);
    };
  }, []);

  if (!data?.length) {
    return "";
  }

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <h3 className="txt-medium">Current Downloads</h3>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} width={"95%"}>
        <Stack direction={"row"} gap={1} mb={3} flexWrap={"wrap"}>
          {itemsToShow.map((item) => (
            <CurrentDownloadBox
              key={item?.id}
              label={item?.file_name}
              min={item?.loaded}
              max={item?.memory}
            />
          ))}
        </Stack>
        {data?.length > initialItemCount && (
          <Box mt={1} mr={1}>
            <Button
              disableRipple
              variant="text"
              color="error"
              size="small"
              sx={{
                fontWeight: 600,
                fontSize: ".8rem",
                textDecorationLine: "underline",
                whiteSpace: "nowrap",
                ":hover": {
                  backgroundColor: "white",
                  textDecorationLine: "underline",
                },
              }}
              onClick={toggleShowAll}
            >
              {showAll ? " View Less" : "View All"}
            </Button>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default CurrentDownload;
