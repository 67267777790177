import { useState, useContext } from "react";
import styles from "../freelancer.module.scss";
import avatar from "assets/images/default.svg";
import {
  Button,
  InputField,
  TextField,
  Snackbar,
  DatePicker,
} from "components";
import AuthServices from "api/services/auth-services";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { GlobalContext, actions } from "context";
import { ConfirmDialog } from "components";
import { isEmailValid } from "utils/validator";
import { Stack } from "@mui/material";
import ButtonWithIcon from "components/IconButton/icon-button";
import { Typography } from "@material-ui/core";
import { convertImageToBase64WithMetadata } from "utils/videoUtils";

const fieldErrors = {
  name: "Enter Name",
  email: "Enter Email",
  phone: "Enter phone number",
  date_joining: "Enter date of joining",
};

const AddFreelancer = ({ handleClose, open }) => {
  const [newFreeLancer, setNewFreeLancer] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState(null);
  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({
    message: "",
    class: "error",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [invalidFileSize, setInvalidFileSize] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [minLengthInvalid, setMinLengthInvalid] = useState(false);

  const { dispatch } = useContext(GlobalContext);

  const today = new Date().toLocaleDateString();

  const dateFormatToInput = (val) => {
    const [month, date, year] = val.split("/");

    return `${year}-${month < 10 ? `0${month}` : month}-${
      date < 10 ? `0${date}` : date
    }`;
  };
  const maxDate = dateFormatToInput(today);

  const addNewFreeLancer = (key, value) => {
    const val = value;
    const data = {
      ...newFreeLancer,
      [key]: key === "email" ? val.toLowerCase() : val,
    };
    setNewFreeLancer(data);
  };

  const validate = () => {
    let isInvalid = false;
    const inputFields = Object.keys(fieldErrors);
    inputFields.forEach((item) => {
      if (!newFreeLancer[item]) {
        isInvalid = true;
        setIsInvalidForm(true);
        return;
      }
    });
    if (!isInvalid) {
      const { email } = newFreeLancer;

      const isValidEmail = isEmailValid(email);
      setInvalidEmail(!isValidEmail);
      if (
        invalidFileSize ||
        invalidEmail ||
        !isValidEmail ||
        minLengthInvalid
      ) {
        setIsInvalidForm(true);
      } else {
        setIsInvalidForm(false);
        setInvalidEmail(false);
        addFreelancer();
      }
    }
  };

  const addFreelancer = async () => {
    const base64Image = imageFile
      ? await convertImageToBase64WithMetadata(imageFile)
      : null;

    // const formData = new FormData();

    const payload = {
      ...newFreeLancer,
      profile: base64Image?.base64,
      content_type: base64Image?.content_type,
      filename: base64Image?.filename,
      ...(newFreeLancer.dob && { dob: dateFormat(newFreeLancer.dob) }),
      ...(newFreeLancer.date_joining && {
        date_joining: dateFormat(newFreeLancer.date_joining),
      }),
    };

    // for (let key in payload) {
    //   formData.append(key, payload[key]);
    // }
    storeHandler(actions.SHOW_LOADER, true);

    try {
      await AuthServices.addFreelancer(payload);
      storeHandler(actions.FREELANCER_DATA, []);
      setImage();
      setImageFile();
      handleClose(null, null, true);
      storeHandler(actions.SHOW_LOADER, false);
      setOpenDialog(true);
    } catch (err) {
      const { data } = err;
      const { message } = data || {};
      setShowSnackBar(true);
      const content = {
        message: message || "Please try again later",
        class: "error",
      };
      setSnackBarContent({ ...content });
      storeHandler(actions.SHOW_LOADER, false);
    }
  };
  const dateFormat = (val) => {
    const [month, date, year] = new Date(val).toLocaleDateString().split("/");
    return `${date}/${month}/${year}`;
  };

  const addImg = (event) => {
    if (
      !event.target ||
      !event.target.files ||
      event.target.files.length === 0
    ) {
      console.error("No file selected or invalid event target");
      return;
    }

    const img = event.target.files[0];

    if (!["image/jpeg", "image/jpg"].includes(img.type)) {
      console.error("Selected file is not a JPEG or JPG image");
      setInvalidFileSize(true);
      return;
    }

    // Validate file size
    const fileSizeMB = img.size / 1024 / 1024;
    const isValidFileSize = fileSizeMB <= 2;

    if (isValidFileSize) {
      setImage(URL.createObjectURL(img));
      setImageFile(img);
      setInvalidFileSize(false);
    } else {
      setInvalidFileSize(true);
    }
  };

  const removeImg = () => {
    setImage(null); // Clear the image URL
    setImageFile(null); // Clear the file reference
    setInvalidFileSize(false); // Optionally reset the invalid file size flag
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  const emailValidator = (event) => {
    const value = event.target.value;
    setInvalidEmail(!isEmailValid(value));
  };
  const textValidator = (event) => {
    const value = event.target.value;
    const invalid = value.length < 3 || value.length > 30;
    setMinLengthInvalid(invalid);
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "1rem",
          },
        }}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogContent>
          {/* <p className="txt-primary txt-xlg m-0 text-center mb-3">
            Add Contractor
                  </p> */}
          <Stack
            mt={".5rem"}
            mb={"1.5rem"}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <p
              className="txt-primary txt-xlg m-0 px-md-3"
              style={{ fontWeight: "500" }}
            >
              Add Contractor
            </p>
            <ButtonWithIcon
              startIcon={<CloseIcon />}
              style={{ padding: ".5rem 1rem" }}
              onClick={handleClose}
            >
              Close
            </ButtonWithIcon>
          </Stack>
          <div className="row pb-4">
            <div className="d-flex flex-wrap  px-0 px-md-3 col-md-8 col-12">
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">Name</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  placeholder="Full Name"
                  alphanumeric={true}
                  onBlur={textValidator}
                  onChange={(event) =>
                    addNewFreeLancer("name", event.target.value)
                  }
                  helperText={
                    (isInvalidForm &&
                      !newFreeLancer["name"] &&
                      fieldErrors["name"]) ||
                    (minLengthInvalid &&
                      (newFreeLancer["name"]?.length > 30
                        ? "Enter less than 30 characters"
                        : "Enter minimum 3 characters"))
                  }
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">Email</label>
                <InputField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  placeholder="Email Id"
                  onBlur={emailValidator}
                  value={newFreeLancer.email || ""}
                  onChange={(event) =>
                    addNewFreeLancer("email", event.target.value)
                  }
                  helperText={
                    (isInvalidForm &&
                      !newFreeLancer["email"] &&
                      fieldErrors["email"]) ||
                    (invalidEmail && "Enter valid email id")
                  }
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">Address</label>
                <InputField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  placeholder="Add Address"
                  onChange={(event) =>
                    addNewFreeLancer("address", event.target.value)
                  }
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">ZIP</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  type="number"
                  fullWidth
                  placeholder="City zip code"
                  onChange={(event) =>
                    addNewFreeLancer("zipcode", event.target.value)
                  }
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">Phone</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  type="number"
                  fullWidth
                  placeholder="Mobile Number"
                  onChange={(event) =>
                    addNewFreeLancer("phone", event.target.value)
                  }
                  helperText={
                    (isInvalidForm &&
                      !newFreeLancer["phone"] &&
                      fieldErrors["phone"]) ||
                    (invalidEmail && "Enter phone number")
                  }
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">Alternate Phone</label>
                <TextField
                  variant="filled"
                  className={"mr-3"}
                  fullWidth
                  type="number"
                  placeholder="Alternate Mobile Number"
                  onChange={(event) =>
                    addNewFreeLancer("alternate_phone", event.target.value)
                  }
                />
              </div>
              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md">Date of Birth</label>
                <DatePicker
                  className="mt-0"
                  value={newFreeLancer?.dob || null}
                  onChange={(val) => addNewFreeLancer("dob", val)}
                />
              </div>

              <div className="col-sm-6 col-12 mb-3">
                <label className="txt-bold txt-md required-label">
                  Date of Joining
                </label>
                <DatePicker
                  className="mt-0"
                  value={newFreeLancer?.date_joining || null}
                  onChange={(val) => addNewFreeLancer("date_joining", val)}
                  helperText={
                    isInvalidForm &&
                    !newFreeLancer["date_joining"] &&
                    fieldErrors["date_joining"]
                  }
                />
              </div>
            </div>
            {/* <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
              <div
                className={`d-flex flex-column justify-content-between ${styles.addImg}`}
              >
                <div className="text-center">
                  {" "}
                  <img
                    src={image ? image : avatar}
                    alt=""
                    className={styles.upload}
                  />
                </div>
                <label
                  className={`txt-primary ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`}
                  for="uploadImg"
                >
                  <img src={upload} className="mr-2" alt="upload" />
                  Upload Img
                </label>
                <TextField
                  className={styles.uploadImg}
                  inputProps={{
                    type: "file",
                    accept: "image/*",
                    id: "uploadImg",
                  }}
                  onChange={addImg}
                />
              </div>
              {invalidFileSize && (
                <span className="mt-2 txt-error">
                  Image size should be less than 2MB
                </span>
              )}
            </div> */}

            <div className="align-items-center col-12 col-md-4 d-flex flex-column flex-wrap">
              <div
                className={`d-flex flex-column justify-content-between ${styles.addImg}`}
              >
                <div className="text-center">
                  {" "}
                  <img
                    src={image ? image : avatar}
                    alt=""
                    className={styles.upload}
                  />
                </div>
                {/* <label
                  className={`txt-primary ${styles.uploadTitle} mb-0 py-2 d-flex align-items-center justify-content-center cursor`}
                  for="uploadImg"
                >
                  {" "}
                  <img src={upload} className="mr-2" alt="upload" /> Upload Img
                </label>
                <TextField
                  className={styles.uploadImg}
                  inputProps={{
                    type: "file",
                    accept: "image/*",
                    id: "uploadImg",
                  }}
                  onChange={addImg}
                              /> */}

                {!image && (
                  <ButtonWithIcon startIcon={<UploadIcon />}>
                    Upload Image
                    <TextField
                      className={styles.uploadImg}
                      inputProps={{
                        type: "file",
                        accept: "image/*",
                        id: "uploadImg",
                      }}
                      onChange={addImg}
                    />
                  </ButtonWithIcon>
                )}

                {image && (
                  <ButtonWithIcon onClick={removeImg} startIcon={<CloseIcon />}>
                    Remove Image
                  </ButtonWithIcon>
                )}
              </div>

              <Typography
                variant="caption"
                className=" my-1"
                style={{ color: "var(--text-disabled)" }}
              >
                Image size should be 2MB Max
              </Typography>

              {invalidFileSize && (
                <Typography
                  variant="caption"
                  style={{ color: "var(--error-main)" }}
                >
                  Upload a .jpg or .jpeg image up to 2 MB.
                </Typography>
              )}

              {/* {invalidFileSize && (
                <span className="mt-2 txt-error">
                  Image size should be less than 2MB
                </span>
              )} */}
            </div>
            <div className="col-12 text-center">
              <Button
                variant="contained"
                color="primary"
                className="mt-3"
                onClick={validate}
                style={{
                  padding: ".5rem 1rem",
                  background:
                    "linear-gradient(100deg, var(--error-main) 0%, rgba(255,135,135,1) 55%, var(--error-main) 100%)",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        isPrompt={true}
        open={openDialog}
        onClose={setOpenDialog}
        message={"Contractor Added Successfully"}
      />
      <Snackbar
        open={showSnackBar}
        message={snackbarContent?.message || ""}
        className={snackbarContent?.class || ""}
        autoHideDuration={4000}
        closeSnackBar={setShowSnackBar}
      />
    </>
  );
};

export default AddFreelancer;
