import styles from "./header.module.scss";
import avatar from "assets/images/default.svg";
import { ReactComponent as LogoutIcon } from "assets/images/logout.svg";
import { ReactComponent as ContractorIcon } from "assets/images/Contractor.svg";
import { ReactComponent as GalleryIcon } from "assets/images/Gallery.svg";
import { ReactComponent as AdminIcon } from "assets/images/admin-ico.svg";
import Logo from "assets/images/Logo.svg";
import { ReactComponent as DashboardIcon } from "assets/images/Dashboard.svg";
import { ReactComponent as Downloads } from "assets/images/Downloads.svg";
import { Button, Menu, ConfirmDialog } from "components";
import { NavLink, useLocation } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthServices from "api/services/auth-services";
import { GlobalContext, actions } from "context";
import { Paper, Typography, useTheme } from "@material-ui/core";
import { Stack } from "@mui/material";
import { checkBoolean, getBase64Image } from "utils/videoUtils";

const Header = () => {
  const {
    dispatch,
    state: { refreshProfile, userId, dashboardData, isAdmin },
  } = useContext(GlobalContext);
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const { setting } = dashboardData || {};
  const { playback_restrict } = setting || {};
  const { playback } = playback_restrict || {};
  const { restrict, status } = playback || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState();
  const [limit, setLimit] = useState();

  const [profile, setProfile] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [profileImg, setProfileImg] = useState();

  const isAdminPath = pathname === "/users";
  const isFreelancer = pathname === "/contractors" || pathname === "/user-info";
  const isFreelancerInfo = !userId.admin && pathname === "/user-info";
  const isAdminInfo = userId.admin && pathname === "/user-info";
  const isDashboard = pathname === "/";
  const isGallery = pathname === "/gallery";
  const isDownload = pathname === "/video-download";

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setIsActive(status);
    setLimit(restrict);
  }, [status, restrict]);

  useEffect(() => {
    refreshProfile && setProfile({});
  }, [refreshProfile]);

  useEffect(() => {
    if (refreshProfile && !profile.account_id) {
      getProfile();
    }
  }, [profile]);

  const getProfile = async () => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const profile_details = await AuthServices.getAdminProfile();

      localStorage.setItem("isAdmin", !profile_details?.qa_user);
      storeHandler(actions.LOG_IN, {
        isLoggedIn: true,
        isAdmin: !profile_details?.qa_user,
      });

      setProfile(profile_details);
      storeHandler(actions.PROFILE, profile_details);
      storeHandler(actions.PROFILE_DETAILS, profile_details?.account_id);
      storeHandler(actions.SHOW_LOADER, false);
      storeHandler(actions.REFRESH_PROFILE, false);
      if (!!profile_details.profile) {
        setProfileImg(profile_details?.profile);
        // getBase64Image(profile_details?.profile, setProfileImg);
      }
    } catch (error) {
      storeHandler(actions.SHOW_LOADER, false);
      logOut();
    }
  };

  // const validate = async () => {
  //   storeHandler(actions.SHOW_LOADER, true);
  //   await AuthServices.setPlaybackLimit(limit);
  //   storeHandler(actions.SHOW_LOADER, false);
  //   handleClose();
  // };

  // const switchPlayback = async (isChecked) => {
  //   setIsActive(isChecked);
  //   const url = isChecked ? "activate" : "deactivate";
  //   storeHandler(actions.SHOW_LOADER, true);
  //   await AuthServices.activatePlayback(url);
  //   storeHandler(actions.SHOW_LOADER, false);
  //   setOpenDialog(true);
  // };

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <Paper
      variant="outlined"
      style={{
        borderRadius: "0 0 .6rem .6rem",
      }}
      className={`${styles.headerWrapper} px-sm-3 py-sm-2 p-2 d-flex justify-content-between flex-wrap`}
    >
      <div className="d-flex">
        <img src={Logo} alt="logo" className={styles.logo} />
        <div className={`${styles.admin} mt-3`}>
          {profile && (profile?.qa_user ? "(QA)" : "(Admin)")}
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap">
        <Button
          variant="outlined"
          size={"small"}
          disableRipple
          style={{
            backgroundColor: isDashboard ? "#c81e1e" : "transparent",
            border: "none",
          }}
          className={`py-sm-2 py-1 mr-md-4 mr-2 ${
            isDashboard ? "" : "outline-none"
          } ${styles.btn}`}
        >
          <NavLink
            to="/"
            className={`${styles.navLink}`}
            activeClassName={styles.selected}
            style={{
              color: isDashboard
                ? "white"
                : isDark
                ? "var(--primary-main)"
                : "var(--text-primary)",
            }}
            exact={true}
          >
            <DashboardIcon className={`mr-2 ${styles.icon}`} />
            Dashboard
          </NavLink>
        </Button>

        <Button
          variant="outlined"
          size={"small"}
          disableRipple
          style={{
            backgroundColor: isGallery ? "#c81e1e" : "transparent",
            border: "none",
          }}
          className={`py-sm-2 py-1 mr-md-4 mr-2 ${
            isGallery ? "" : "outline-none"
          } ${styles.btn}`}
        >
          <NavLink
            to="/gallery"
            activeClassName={styles.selected}
            exact={true}
            className={` ${styles.navLink}`}
            style={{
              color: isGallery
                ? "white"
                : isDark
                ? "var(--primary-main)"
                : "var(--text-primary)",
            }}
          >
            <GalleryIcon className={`mr-2 ${styles.icon}`} />
            Gallery
          </NavLink>
        </Button>
        <Button
          variant="outlined"
          size={"small"}
          disableRipple
          style={{
            backgroundColor: isFreelancer ? "#c81e1e" : "transparent",
            border: "none",
          }}
          className={`py-sm-2 py-1 mr-md-4 mr-2 ${
            isFreelancer ? "" : "outline-none"
          } ${styles.btn}`}
        >
          <NavLink
            to="/contractors"
            activeClassName={styles.selected}
            exact={true}
            className={`${styles.navLink} ${
              isFreelancer && styles["custom-active"]
            }`}
            style={{
              color: isFreelancer
                ? "white"
                : isDark
                ? "var(--primary-main)"
                : "var(--text-primary)",
            }}
          >
            <ContractorIcon className={`mr-2 ${styles.icon}`} />
            Contractors
          </NavLink>
        </Button>
        {checkBoolean(isAdmin) && (
          <Button
            variant="outlined"
            size={"small"}
            disableRipple
            style={{
              backgroundColor: isDownload ? "#c81e1e" : "transparent",
              border: "none",
            }}
            className={`py-sm-2 py-1 mr-md-4 mr-2 ${
              isDownload ? "" : "outline-none"
            } ${styles.btn}`}
          >
            <NavLink
              to="/video-download"
              activeClassName={styles.selected}
              exact={true}
              className={`${styles.navLink}`}
              style={{
                color: isDownload
                  ? "white"
                  : isDark
                  ? "var(--primary-main)"
                  : "var(--text-primary)",
              }}
            >
              <Downloads className={`mr-2 ${styles.icon}`} />
              Downloads
            </NavLink>
          </Button>
        )}
        {checkBoolean(isAdmin) && (
          <Button
            variant="outlined"
            size={"small"}
            disableRipple
            style={{
              backgroundColor: isAdminPath ? "#c81e1e" : "transparent",
              border: "none",
            }}
            className={`py-sm-2 py-1 mr-md-4 mr-2 ${
              isAdminPath ? "" : "outline-none"
            } ${styles.btn}`}
          >
            <NavLink
              to="/users"
              activeClassName={styles.selected}
              exact={true}
              className={`${styles.navLink}`}
              style={{
                color: isAdminPath
                  ? "white"
                  : isDark
                  ? "var(--primary-main)"
                  : "var(--text-primary)",
              }}
            >
              <AdminIcon className={`mr-2 ${styles.icon}`} />
              Users
            </NavLink>
          </Button>
        )}

        <div
          className={`pl-md-4 pl-3 ${styles.divider} d-flex align-items-center`}
        >
          <Menu
            anchorEl={anchorEl2}
            keepMounted
            open={Boolean(anchorEl2)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                marginLeft: "40px",
                marginTop: "15px",
              },
            }}
            getContentAnchorEl={null}
          >
            <div
              className="logout"
              style={{ display: "flex", height: "5.5rem", minWidth: "18rem" }}
            >
              {" "}
              <div className=" ml-3">
                <img
                  src={profileImg || avatar}
                  width="70px"
                  height="100%"
                  alt="user"
                />
              </div>
              <div className="ml-3">
                <Typography variant="h6" className="txt-lg ml-2">
                  {profile.name || ""}
                </Typography>
                <div style={{ marginTop: "-5px", marginBottom: "15px" }}>
                  <span
                    style={{
                      color: "#C20000",
                    }}
                    className="txt-md ml-2"
                  >
                    {profile?.account_id}
                  </span>
                </div>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  className=" ml-2 mt-2 cursor"
                  onClick={logOut}
                >
                  {" "}
                  <LogoutIcon width="1.3rem" />
                  <div
                    style={{
                      color: "#C20000",
                    }}
                    className=" ml-2"
                  >
                    Sign Out
                  </div>
                </Stack>
              </div>
            </div>
          </Menu>
          <img
            src={profileImg || avatar}
            alt="dashboard-icon"
            className={`${styles.avatar} cursor`}
            onClick={(event) => setAnchorEl2(event.currentTarget)}
          />
        </div>
      </div>
      <ConfirmDialog
        isPrompt={true}
        open={openDialog}
        onClose={setOpenDialog}
        message={`Playback restrictions ${
          isActive ? "activated" : "deactivated"
        } successfully`}
      />
    </Paper>
  );
};

export default Header;
