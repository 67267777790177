import React, { useState, useEffect, useContext } from "react";
import { Box, Checkbox, ConfirmDialog } from "components";
import play from "assets/images/play.svg";
import clock from "assets/images/clock.svg";
import { ReactComponent as DownloadIcon } from "assets/images/video-download-outlined.svg";
import VideoPlayer from "../video-player/video-player";
import RenamePopup from "../rename-popup/rename-popup";
import visibility from "assets/images/visibility.png";
import thumbnail_default from "assets/images/thumbnail.png";
import styles from "../freelancer-info.module.scss";
import AuthServices from "api/services/auth-services";
import { ReactComponent as EditIcon } from "assets/images/video-edit-outlined.svg";
import { ReactComponent as TrashIcon } from "assets/images/video-delete-outlined.svg";
import { ReactComponent as CheckIcon } from "assets/images/white-check.svg";
import { ReactComponent as Decline } from "assets/images/decline.svg";

import moment from "moment";
import { Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { simulatePostApi } from "app/downloadfn";
import { actions, GlobalContext } from "context";
import { checkBoolean, convertSecondsToHMS } from "utils/videoUtils";

const Circle = () => (
  <svg
    clip-rule="evenodd"
    fill-rule="evenodd"
    fill="white"
    stroke-linejoin="round"
    stroke-miterlimit="2"
    viewBox="0 0 48 48"
    width="70px"
    height="70px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m11.998 2c5.517 0 9.997 4.48 9.997 9.998 0 5.517-4.48 9.997-9.997 9.997-5.518 0-9.998-4.48-9.998-9.997 0-5.518 4.48-9.998 9.998-9.998zm0 1.5c-4.69 0-8.498 3.808-8.498 8.498s3.808 8.497 8.498 8.497 8.497-3.807 8.497-8.497-3.807-8.498-8.497-8.498z"
      fill-rule="nonzero"
    />
  </svg>
);
const CheckedCircle = () => (
  <svg viewBox="0 0 48 48" width="36px" height="36px">
    <g id="surface401789">
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "white",
          fillOpacity: 1,
        }}
        d="M 44 24 C 44 35.046875 35.046875 44 24 44 C 12.953125 44 4 35.046875 4 24 C 4 12.953125 12.953125 4 24 4 C 35.046875 4 44 12.953125 44 24 Z M 44 24 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "#26AAE0",
          fillOpacity: 1,
        }}
        d="M 34.601562 14.601562 L 21 28.199219 L 15.398438 22.601562 L 12.601562 25.398438 L 21 33.800781 L 37.398438 17.398438 Z M 34.601562 14.601562 "
      />
    </g>
  </svg>
);
const VideoCard = ({
  info,
  selectedVideos,
  setSelectedVideos,
  selectAll,
  index,
  isBulkSelect,
  getFreelancerDetails,
  unselectAll,
  setUnSelectAll,
  freelancerDetail,
  deleteVideos,
  showPlayer = true,
}) => {
  const {
    dispatch,
    state: { isAdmin },
  } = useContext(GlobalContext);
  const IS_ADMIN = checkBoolean(isAdmin);

  const [showVideo, setShowVideo] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [select, setSelect] = useState(false);
  const [openRenamePrompt, setOpenRenamePrompt] = useState(false);
  const [videoUrl, setvideoUrl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { name, duration, views, thumbnail, date, video, download_url } =
    info || {};
  const url = info.video;
  useEffect(() => {
    if (selectAll) {
      setSelect(true);
    } else {
      setSelect(false);
    }
  }, [selectAll]);

  useEffect(() => {
    !isBulkSelect && setSelect(false);
  }, [isBulkSelect]);

  useEffect(() => {
    if (unselectAll) {
      setSelect(false);
    }
  }, [unselectAll]);

  const selectVideo = (event) => {
    const checked = event.target.checked;
    setSelect(checked);
    unselectAll && setUnSelectAll(false);
    let videos = [...selectedVideos];
    if (checked) {
      videos.push(url);
    } else {
      videos = [...videos.filter((item) => item !== url)];
    }
    setSelectedVideos(videos);
  };

  const handleClose = (event, reason, showPrompt) => {
    setShowPopup(false);
    // getFreelancerDetails();
    showPrompt && setOpenRenamePrompt(true);
  };

  const showVideoPlayer = async (e) => {
    if (!showPlayer) {
      return;
    }
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await simulatePostApi(info);
      // const formData = new FormData();
      // formData.append("url", data?.[0]);
      // const { message } = await AuthServices.getVideoURL(video, formData);
      // const { url } = message;
      storeHandler(actions.SHOW_LOADER, false);
      setvideoUrl(data?.[0]);
      setShowVideo(true);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const deleteVideo = async (e) => {
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const data = await AuthServices.deleteVideo({ id: [info?.id] });
      storeHandler(actions.SHOW_LOADER, false);
      getFreelancerDetails();
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const downloadVideo = async (e) => {
    e?.stopPropagation();
    storeHandler(actions.SHOW_LOADER, true);
    try {
      const res_url = await simulatePostApi(info);

      if (!res_url?.length) {
        throw new Error("URL Length is 0");
      }

      const videoUrl = res_url[0];
      const filename = "video.mp4";

      const a = document.createElement("a");
      a.href = videoUrl;
      a.target = "_blank";
      a.download = filename;
      a.style.display = "none";

      document.body.appendChild(a);

      a.click();

      // Cleanup
      document.body.removeChild(a);

      storeHandler(actions.SHOW_LOADER, false);
    } catch (error) {
      console.log(error);
      storeHandler(actions.SHOW_LOADER, false);
    }
  };

  const onConfirm = () => {
    deleteVideo();
    setOpenDialog(false);
  };

  const handImgLoadError = (event) => {
    event.target.src = thumbnail_default;
  };

  const storeHandler = (type, payload) => dispatch({ type, payload });

  return (
    <>
      <div className={`${styles.videoCard}`} style={{ height: "100%" }}>
        {isBulkSelect && (
          <div className={`${styles.check}`}>
            <Checkbox
              icon={<Circle />}
              checkedIcon={<CheckedCircle />}
              color="primary"
              checked={select}
              onClick={selectVideo}
              id={`${index}-video`}
            />
          </div>
        )}
        <div className={`${styles.videoPreview}`}>
          <img
            src={info?.thumbnail || thumbnail_default}
            alt="preview"
            className={`${styles.preview} cursor`}
            loading="lazy"
            onError={handImgLoadError}
          />
          <div
            className={`${styles.videoPreviewOverlay} d-flex align-items-start cursor`}
            onClick={showVideoPlayer}
          >
            <div
              className="d-flex justify-content-between flex-grow-1"
              style={{ height: "2rem" }}
            >
              <div
                className="txt-grey d-flex align-items-center txt-md"
                style={{ position: "relative", top: ".2rem", left: ".2rem" }}
              >
                {/* <img src={play} alt="play" className={`mr-1 ${styles.play}`} /> */}
                {/* {duration} */}
                {info?.approval === true && <CheckIcon width={"1.5rem"} />}
                {info?.approval === false && <Decline width={"1.5rem"} />}
              </div>
              <div
                className="txt-white d-flex align-items-center txt-md"
                style={{
                  background: "var(--error-main)",
                  padding: "0 .5rem",
                  fontSize: ".7rem",
                }}
              >
                {convertSecondsToHMS(duration)}
                {/* <img
                  src={visibility}
                  alt="play"
                  className={`mr-1 ${styles.play}`}
                />
                {views} */}
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.videoCardBoxWidth}`}>
          <div
            className={`${styles.videoDate} txt-smd py-2 px-2 d-flex justify-content-between`}
            style={{ background: "var(--error-main)" }}
          >
            <div>
              {/* <img src={clock} alt="clock" className={`mr-1`} /> */}
              <span className="txt-xs txt-white">
                {moment(new Date(date)).local().format("YYYY/MMM/DD")}
              </span>
            </div>{" "}
            {IS_ADMIN && (
              <div className="d-flex justify-content-end">
                <TrashIcon
                  width={"1rem"}
                  height={"1rem"}
                  className="cursor"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDialog(true);
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={`txt-smd py-2 px-2 d-flex justify-content-between`}
            style={{ background: "black" }}
          >
            <span
              style={{
                wordBreak: "break-all",
                paddingRight: "10px",
                color: "white",
              }}
            >
              {name}
            </span>
            {IS_ADMIN && (
              <Stack
                direction="row"
                gap={".5rem"}
                alignItems="center"
                justifyContent="center"
              >
                <EditIcon
                  width={"1rem"}
                  height={"1rem"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPopup(true);
                  }}
                  className="cursor"
                />
                <Box
                  // onClick={downloadVideo}
                  // href={`${download_url}`}
                  // download={name}
                  // target={"_blank"}
                  className={select ? "selected-videos" : "non-selected"}
                  style={{
                    position: "relative",
                    top: ".2rem",
                    cursor: "pointer",
                  }}
                >
                  <DownloadIcon
                    width={"1rem"}
                    height={"1rem"}
                    onClick={downloadVideo}
                  />
                </Box>
              </Stack>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <RenamePopup
          freelancerDetail={freelancerDetail}
          filename={name}
          open={showPopup}
          id={info?.id}
          onConfirm={handleClose}
          getFreelancerDetails={getFreelancerDetails}
        />
      )}
      {showVideo && (
        <VideoPlayer
          closePreview={setShowVideo}
          video={videoUrl}
          name={name}
          thumbnail={thumbnail}
        />
      )}
      <ConfirmDialog
        isPrompt={true}
        open={openRenamePrompt}
        onClose={setOpenRenamePrompt}
        message={`Video name updated successfully`}
      />
      <ConfirmDialog
        isPrompt={false}
        open={openDialog}
        onClose={setOpenDialog}
        onConfirm={onConfirm}
        count={1}
        isDeleteVideo={true}
        customDelete={true}
      />
    </>
  );
};

export default VideoCard;
