import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CustomButtonBox = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    textTransform: "capitalize",
    fontFamily: "montserrat-light",
    fontWeight: 600,
    borderRadius: "7px",
    boxShadow: "none",
    fontSize: ".9rem",
    svg: {
      width: "17px",
    },
  },
  "&.MuiButton-containedError": {
    background:
      "linear-gradient(126.44deg,#c81e1e 36.18%,#e54545 88.76%,#950000 129.5%)",
  },
}));

function ButtonBox({ ...rest }) {
  return <CustomButtonBox {...rest} />;
}
export default ButtonBox;
