import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import searchIcon from '../../assets/images/Search rbg.svg'
import styles from './input.module.scss'
const InputField = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        disableUnderline: true,
          ...(!!props.withAdronment && {endAdornment: <InputAdornment position="end"><img src={searchIcon} alt="search" className={styles.searchIcon}/></InputAdornment>})
      }}
    />
  );
};

export default InputField;
