import "./video_player.css";
import CustomVideoPlayer from "./components/CustomVideoPlayer";
import { useState } from "react";
import { Modal } from "@material-ui/core";
import { Button } from "components";

const VideoPlayer = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  return (
    <>
      <Button
        variant="contained"
        style={{ width: "200px", marginTop: "50px" }}
        onClick={handleOpen}
      >
        Open VideoPlayer
      </Button>
      <Modal open={openModal} onClose={handleClose}>
        <CustomVideoPlayer
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
        />
      </Modal>
    </>
  );
};

export default VideoPlayer;
